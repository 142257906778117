/** @format */

import { EndPoints } from "src/api/endPoints";
import { GetAPI } from "src/api/request";
import { Merchant } from "src/interface/merchant";
import { useAppProps, useMerchantDetailsProps } from "./storeTypes";
import getSymbolFromCurrency from "currency-symbol-map";

type SetState = (partial: Partial<useAppProps> | ((state: useAppProps) => Partial<useAppProps>), replace?: boolean) => void;

export const useMerchantDetails = (set: SetState, get: () => useAppProps): useMerchantDetailsProps => ({
   merchantDetails: {} as Merchant,
   setMerchantDetails: (merchantId: string) => {
      GetAPI(EndPoints.merchantEndpoints.MerchantDetails(merchantId)).then((res: any) => {
         let merchantDetails: Merchant = {
            ...res.data,
            show_menu_with_order: false,
            merchant_id: merchantId,
            currency: getSymbolFromCurrency(res?.data?.currency),
         };
         if (merchantDetails.plan_name === "all-in" || merchantDetails.plan_name === "trial") {
            merchantDetails.show_menu_with_order = true;
         }
         set({ merchantDetails: merchantDetails });
      });
   },
});
