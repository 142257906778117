import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import { Container, NavItem, SubContainer } from "./styles";
import CloseIcon from "@mui/icons-material/Close";

function SideBar({ setClick }: any) {
  const NavItems = [
    {
      title: "Home",
      to: "/",
    },
    {
      title: "Contact us",
      to: "/contact-us",
    },
    {
      title: "About us",
      to: "/about-us",
    },
  ];

  return (
    <Container>
      <div
        style={{
          float: "right",
          position: "fixed",
          right: "30%",
          top: "1%",
        }}
      >
        <button
          style={{ backgroundColor: "#fff", border: "none", marginTop: "10px" }}
          onClick={() => setClick((p: any) => !p)}
        >
          {" "}
          <CloseIcon />{" "}
        </button>
      </div>

      <Logo />
      <hr />

      <SubContainer>
        {NavItems.map((item, index) => {
          return (
            <div style={{ width: "90%", paddingLeft: "5%" }}>
              <Link style={{ textDecoration: "none" }} to={item.to} key={index}>
                <NavItem>{item.title}</NavItem>
              </Link>
              <hr />
            </div>
          );
        })}
      </SubContainer>
    </Container>
  );
}

export default SideBar;
