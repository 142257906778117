/** @format */

import { EndPoints } from "src/api/endPoints";
import { GetAPI } from "src/api/request";
import { Merchant } from "src/interface/merchant";
import { useAppProps, useMenuProps } from "./storeTypes";
import getSymbolFromCurrency from "currency-symbol-map";
import { Category } from "src/interface/category";
import { AddOnItem, Customization, Item } from "src/interface/item";

type SetState = (partial: Partial<useAppProps> | ((state: useAppProps) => Partial<useAppProps>), replace?: boolean) => void;

export const useMenu = (set: SetState, get: () => useAppProps): useMenuProps => ({
   menu: [] as Category[],
   setMenu: (categories: Category[]) => {
      const { merchant_id } = get().merchantDetails;
      GetAPI(EndPoints.menuEndPoints.Menu(merchant_id)).then((res: any) => {
         set({ menu: res.data });
      });
   },
   getAddOnsForItem: (item: Item) => {
      return [] as AddOnItem[];
   },
   getCustomizationForItem: (item: Item) => {
      return [] as Customization[];
   },
});
