export const TABS = [
    {
        title: "All Items",
        value: "all",
    },
    {
        title: "Our Recommendations",
        value: "recommendedItems",
    },
    {
        title: "Best selling",
        value: "bestSellingItems",
    },
    {
        title: "Newly added",
        value: "newlyAddedItems",
    },
];