/** @format */

import AddIcon from "@mui/icons-material/Add";
import Sucess from "canvas-confetti";
import useRazorpay from "react-razorpay";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIsAuthenticated } from "src/hooks/useIsAuthenticated";
import { useApp } from "src/store/useApp";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import ApplyCoupon from "../../component/ApplyCoupon";
import CartCard from "../../component/CartCard";
import EmptyCart from "../../component/EmptyCart";
import HurrayModal from "../../component/HurrayModal";
import LoyaltyBanner from "../../component/LoyaltyBanner";
import OfferMessage from "../../component/OfferMessage";
import OrderConfirmationModal from "../../component/OrderConfirmationModal";
import OrderPlaced from "../../component/OrderPlaced";
import { Text } from "../../component/Text";
import ApplyLoyaltyCoinsModal from "../ApplyLoyaltyCoinsModal";
import TotalAmountDetails from "./TotalAmountDetails";

import { useMerchant } from "src/hooks/useMerchant";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import ButtonWithArrow from "src/component/Button";
import { PostAPI, PutAPI } from "src/api/request";
import { EndPoints } from "src/api/endPoints";
import { Order } from "src/interface/order";

const REACT_APP_RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

const CheckoutPage = () => {
   const {
      addMoreItemFlow,
      merchantDetails,
      appliedOffer,
      offers,
      subscribedToLoyalty,
      getCartTotal,
      isOfferTypeIsPercentage,
      setLoyaltyDetails,
      setLoyaltyAvailableCoins,
      getMaximumDiscount,
      getOfferById,
      getCart,
      setOffers,
      getOrderPayload,
      resetCart,
      resetAddMoreItemFlow,
   } = useApp();
   const cart = getCart();
   const cartTotal = getCartTotal();
   const Razorpay = useRazorpay();
   const isAuthenticated = useIsAuthenticated();

   const { currency, loyalty_enabled, dine_in, take_away, delivery } = merchantDetails;
   const { mid, qid } = useParams();
   const appNavigate = useAppNavigate();

   const navigate = useNavigate();
   const [couponView, setCouponView] = useState(false);
   const [hurrayModal, setHurrayModal] = useState(false);
   const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
   const [showApplyLoyaltyCoinsModal, setshowApplyLoyaltyCoinsModal] = useState(false);
   const [isOrderPlaced, setisOrderPlaced] = useState(false);

   const [hurrayLoyaltyModal, setHurrayLoyaltyModal] = useState(false);
   const [loyaltyCoinsUsed, setLoyaltyCoinsUsed] = useState(0);

   const [loyaltyDiscount, setLoyaltyDiscount] = useState(0);

   useEffect(() => {
      setLoyaltyAvailableCoins();
      setLoyaltyDetails();
      setOffers();
   }, []);

   const onBackArrowClick = () => {
      navigate(-1);
   };

   const displayEmptyCart = () => getCart().length === 0;

   const showConfetti = () => {
      Sucess({
         particleCount: 1000,
         spread: 160,
         origin: { y: 0.6, x: 0.5 },
      });
   };

   const openPayment = (payload: any) => {
      const { amount_after_taxes: amount } = payload;
      let updateOrderPayload = { ...payload };
      const options = {
         key: REACT_APP_RAZORPAY_KEY_ID,
         amount: (amount * 100).toString(),
         ...payload.payment_gateway_details,
         handler: async (response: any) => {
            updateOrderPayload = {
               ...payload,
               payment_order_id: response.razorpay_payment_id,
               payment_hash: response.razorpay_signature,
               payment_status: "paid",
               extra_payment: false,
               extra_payment_amount: 0,
               amount_paid: amount,
               order_status: "new_order",
            };
            onUpdateOrder(updateOrderPayload);
         },
      };
      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response: any) {
         onBackArrowClick();
      });

      rzp1.open();
   };

   const navigateToOrderSummary = (order: Order) => {
      showConfetti();
      setisOrderPlaced(true);
      const { customer_id, order_id } = order;
      setTimeout(() => {
         resetCart();
         resetAddMoreItemFlow();
         localStorage.setItem("fromCheckout", "true");
         appNavigate({ path: `/order_summary/${order_id}` });
      }, 4000);
   };

   const onConfirmOrder = () => {
      toggleShowOrderConfirmModal();
      const order = getOrderPayload(qid as string);

      PostAPI(EndPoints.orderEndPoints.CreateOrder, order).then((res: any) => {
         const order: Order = res?.data;
         if (order.order_type === "Take away") {
            openPayment(order);
         } else {
            navigateToOrderSummary(order);
         }
      });
   };

   const onUpdateOrder = (payload: Order) => {
      PutAPI(EndPoints.orderEndPoints.Order(payload.order_id as string), payload).then((res: any) => navigateToOrderSummary(res?.data));
   };

   const handleLoyalty = () => {
      if (!subscribedToLoyalty) {
         if (isAuthenticated) {
            appNavigate({
               path: "/loyaltyprogram",
            });
         } else {
            localStorage.setItem("redirect", "loyaltyprogram");
            localStorage.setItem("loyaltyRedirect", "checkout");
            appNavigate({
               path: "/login",
            });
         }
      } else {
         toggleShowApplyLoyaltyCoinsModal();
      }
   };

   const displayBillDetails = () => {
      if (cart.length > 0) {
         return true;
      }
   };

   const onPressButton = () => {
      if (isAuthenticated) {
         if (addMoreItemFlow) {
            const payload = getOrderPayload(qid as string);
            console.log("UPDATED ORDER PAYLOAD => ", payload);
            onUpdateOrder(payload);
         } else {
            toggleShowOrderConfirmModal();
         }
      } else {
         appNavigate({ path: "/login" });
      }
   };

   const toggleShowOrderConfirmModal = () => setShowConfirmOrderModal(!showConfirmOrderModal);
   const toggleShowApplyLoyaltyCoinsModal = () => setshowApplyLoyaltyCoinsModal(!showApplyLoyaltyCoinsModal);

   return (
      <div className="w-full flex flex-col items-center">
         <div className="w-[95%] ">
            <HurrayModal
               open={hurrayModal}
               onClose={() => setHurrayModal(false)}
               statementPrimary={`You are saving ${currency}${getMaximumDiscount(appliedOffer)} on this order`}
               statementSecondary={
                  isOfferTypeIsPercentage(appliedOffer)
                     ? `${getOfferById(appliedOffer)?.discount_val?.toString()}%`
                     : `${currency}${getOfferById(appliedOffer)?.discount_val?.toString()}` + " Off coupon applied!"
               }
            />
            {isOrderPlaced && <OrderPlaced />}
            <div className="checkout-header sticky top-0 p-2 bg-[#fff] gap-[20px]">
               <div className="w-[100%] py-2 flex items-center">
                  <div
                     className="mr-[10px] ring-1 rounded-[50px] ring-[#C3C5C6]"
                     onClick={couponView ? () => setCouponView(false) : onBackArrowClick}>
                     <BackArrowIcon />
                  </div>

                  <Text
                     className="menupage-header-text font-custom font-semibold flex flex-col gap-1"
                     size="1.3rem"
                     color="#0F191A"
                     lHeight="1"
                     align="left">
                     {couponView ? "Offers" : "Cart details"}
                  </Text>
               </div>
            </div>

            <>
               <OfferMessage />

               <OrderConfirmationModal
                  isOpen={showConfirmOrderModal}
                  onConfirm={onConfirmOrder}
                  onClose={toggleShowOrderConfirmModal}
               />
               {displayEmptyCart() ? (
                  <EmptyCart />
               ) : (
                  <div className="checkout-content-container mb-2">
                     <div className="flex items-center justify-between py-3">
                        <div className="text-[18px] font-bold font-custom">Order details</div>
                        <div
                           onClick={onPressButton}
                           className="flex items-center gap-1 justify-between font-semibold text-[#0F6656] font-custom">
                           <AddIcon /> Add items
                        </div>
                     </div>
                     <div className="checkout-items-container">
                        {cart?.map((item) => (
                           <CartCard
                              item={item}
                              key={item.item_id}
                           />
                        ))}
                     </div>
                  </div>
               )}

               {!displayEmptyCart() && (
                  <>
                     {offers.length > 0 && (
                        <>
                           <div className="flex items-center justify-between p-3 px-4 w-[100%]">
                              <div className="text-[18px] font-bold font-custom">Coupons & Benefits</div>
                           </div>
                           {offers?.length > 0 && <ApplyCoupon />}
                        </>
                     )}

                     {loyalty_enabled && (
                        <LoyaltyBanner
                           appliedCoins={loyaltyCoinsUsed > 0}
                           onClick={handleLoyalty}
                           previewDesc={`${currency}${loyaltyDiscount} Off applied using ${loyaltyCoinsUsed} coins`}
                        />
                     )}
                     <ApplyLoyaltyCoinsModal
                        visible={showApplyLoyaltyCoinsModal}
                        onClose={toggleShowApplyLoyaltyCoinsModal}
                     />
                  </>
               )}
               {!displayEmptyCart() && <>{displayBillDetails() && <TotalAmountDetails />}</>}
               {!displayEmptyCart() && (
                  <div className="absolute w-[95%] bottom-[1rem]">
                     <ButtonWithArrow
                        onClickHandler={onPressButton}
                        background="#0F6656"
                        fontColor="white"
                        buttonText={isAuthenticated ? "Confirm order" : "Login to confirm"}
                     />
                  </div>
               )}
            </>
         </div>
      </div>
   );
};

export default CheckoutPage;
