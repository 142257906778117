/** @format */

import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { usePromiseTracker } from "react-promise-tracker";
import { useNavigate, useParams } from "react-router-dom";
import { EndPoints } from "src/api/endPoints";
import { GetAPI } from "src/api/request";
import MenuPageSkeleton from "src/component/Skeletons/MenuPageSkeleton";
import { FILTER_DATA } from "src/constants/itemsFilterData";
import { Item } from "src/interface/item";
import { useApp } from "src/store/useApp";
import getItemRecommender from "../../api/menu/getItemRecommender";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import CartIcon from "../../asset/icons/CartIcon";
import ItemCard from "../../component/ItemCard";
import NoItems from "../../component/NoItems";
import SearchInput from "../../component/SearchInput";
import { Text } from "../../component/Text";
import ToggleChipWithSwitch from "../../component/ToggleChipWithSwitch";
import ViewCartButton from "../../component/ViewCartButton";
import WarningModal from "../../component/WariningModal";
import { QrActions } from "../../store/Qr/reducer";
import { useAppDispatch } from "../../store/hooks";
import { classNames } from "../../utils/classNames";
import { featuredItemsTransformer } from "../HomePage/helper";
import "./style.scss";

interface FilterProps {
   veg: boolean;
   nonVeg: boolean;
   inStock: boolean;
}

interface ItemProps {
   item_id: string;
   item_name: string;
   description: string;
   price: number;
   discount: number;
   price_after_discount: number;
   type: string;
   out_of_stock: string;
   recommended: boolean;
   best_selling: boolean;
   image_url: string;
   merchant_id: string;
   remaining_quantity: string;
   manage_inventory: boolean;
   add_ons: any;
   customizations: any;
}

const MenuPage = () => {
   const dispatch = useAppDispatch();
   const { merchantDetails, getCart, getCartTotal } = useApp();
   const { promiseInProgress } = usePromiseTracker();
   const [detailedItem, setDetailedItem] = useState<ItemProps>();
   const [selectedType, setselectedType] = useState<FilterProps>({
      veg: false,
      nonVeg: false,
      inStock: false,
   });

   const [showSearchResult, setShowSearchResult] = useState(false);
   const [showDetailsModal, setShowDetailsModal] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const { item, mid, qid } = useParams();
   const [loadingItemRecommender, setloadingItemRecommender] = useState(false);
   const [recentlyClickedItem, setrecentlyClickedItem] = useState("");

   const [peopleWhoOrderedItems, setpeopleWhoOrderedItems] = useState([]);

   useEffect(() => {
      dispatch(QrActions.addQr({ mid }));
   }, []);

   const navigate = useNavigate();
   const [toggledId, settoggledId] = useState<any>();

   const fetchItemsByType = () => {
      if (item === "hourly") {
         return GetAPI(EndPoints.menuEndPoints.HourlySpecials(mid as string)).then((res: any) => ({
            ...res?.data,
            items: featuredItemsTransformer(res?.data?.items, "from_hourly_specials"),
         }));
      } else if (item === "daily") {
         return GetAPI(EndPoints.menuEndPoints.DailySpecials(mid as string)).then((res: any) => ({
            ...res?.data,
            items: featuredItemsTransformer(res?.data?.items, "from_daily_specials"),
         }));
      } else {
         return GetAPI(EndPoints.menuEndPoints.CategoryItems(item as string)).then((res: any) => ({
            ...res?.data,
            items: featuredItemsTransformer(res?.data?.items, "from_menu"),
         }));
      }
   };

   const result = useQuery({
      queryKey: ["items", item],
      queryFn: fetchItemsByType,
   });

   let allItems = useMemo(() => result?.data || {}, [result]);

   console.log(allItems);

   const handleShowSearchPage = (value: any) => {
      setSearchValue(value);
   };

   const CATEGORY_NAME = allItems?.["category_name"];

   const CheckItemType = (item: Item) => {
      const isVeg = selectedType.veg && item.type.toLowerCase() === "veg";
      const isNonVeg = selectedType.nonVeg && item.type.toLowerCase() === "non-veg";
      const isInStock = selectedType.inStock && !item.out_of_stock;

      if (!selectedType.veg && !selectedType.nonVeg && !selectedType.inStock) {
         return true;
      }

      if (selectedType.veg && selectedType.inStock) {
         return isVeg && isInStock;
      }

      if (selectedType.veg) {
         return isVeg;
      }

      if (selectedType.nonVeg && selectedType.inStock) {
         return isNonVeg && isInStock;
      }

      if (selectedType.nonVeg) {
         return isNonVeg;
      }
      if (selectedType.inStock) {
         return isInStock;
      }

      return false;
   };

   const onClick = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   const getPeopleAlsoOrdered = async (data: any) => {
      console.log(data, "DATA getPeopleAlsoOrdered");
      setrecentlyClickedItem(data.item_id);
      setloadingItemRecommender(true);
      const request = await getItemRecommender({
         merchantId: data.merchant_id,
         itemId: data.item_id,
         cookieId: data.cookie_id,
         activity: "added_to_cart",
      });
      const payload: any = request.payload;
      setloadingItemRecommender(false);
      if (request.statusCode === 200) {
         if (payload?.response?.length === 0) {
            toast("No items to recommend");
         }

         let PAYLOAD_RESPONSE = featuredItemsTransformer(payload?.response, "from_item_recommendations");
         setpeopleWhoOrderedItems(PAYLOAD_RESPONSE as any);
         window.localStorage.setItem("peopleAlsoOrdered", JSON.stringify(PAYLOAD_RESPONSE || []));
         window.localStorage.setItem("recentlyClickedItem", data.item_id);
      }
      console.log(payload, "RESPONSE getItemRecommender");
   };

   useEffect(() => {
      const item_id = window.localStorage.getItem("recentlyClickedItem");
      setrecentlyClickedItem(item_id !== null ? item_id : "");
      settoggledId(item_id !== null ? item_id : "");
      setpeopleWhoOrderedItems(JSON.parse(window.localStorage.getItem("peopleAlsoOrdered") || "[]") ?? []);
   }, []);

   useEffect(() => {
      if (toggledId === "") {
         window.localStorage.removeItem("recentlyClickedItem");
         window.localStorage.removeItem("peopleAlsoOrdered");
      }
   }, [toggledId]);

   const onClickViewCart = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   const handleShowDetails = (item: ItemProps) => {
      setDetailedItem(item);
      setShowDetailsModal(true);
   };

   const handleUpdateToggle = (id: any) => {
      if (toggledId === id) {
         settoggledId("");
      } else {
         settoggledId(id);
      }
      setrecentlyClickedItem("");
   };

   const handleFilterChange = (key: keyof FilterProps, value: boolean) => {
      setselectedType({
         ...selectedType,
         [key]: value,
      });
   };

   const showViewCartButton = getCartTotal() > 0;

   const ordersCondition = merchantDetails.show_menu_with_order && merchantDetails.accept_orders;

   const sortBasedOnCondition = (a: any, b: any) => (a.out_of_stock === b.out_of_stock ? 0 : a.out_of_stock ? 1 : -1);

   const getAllItems = () => allItems?.item?.slice().sort((a: any, b: any) => sortBasedOnCondition(a, b));

   const filterDataBasedOnValues = (item: Item) => {
      if (searchValue != "") {
         return item.item_name.toLowerCase().includes(searchValue);
      } else if (selectedType.inStock || selectedType.nonVeg || selectedType.veg) {
         return CheckItemType(item);
      } else {
         return true;
      }
   };

   const cartCount = getCart()?.length;

   return (
      <div className="max-h-[100vh]">
         <MenuPageSkeleton isVisible={promiseInProgress} />
         <>
            <WarningModal />
            <Toaster
               position="bottom-center"
               toastOptions={{
                  className: "",
                  style: {
                     marginBottom: "4rem",
                  },
               }}
            />
            <div className="menupage-header bg-[#fff] gap-[20px]">
               <div className="w-[100%] py-2 gap-1 flex items-center">
                  <div
                     className="mr-[10px] ring-1 p-1 rounded-[50px] ring-[#C3C5C6]"
                     onClick={() => {
                        navigate(-1);
                     }}>
                     <BackArrowIcon />
                  </div>
                  {allItems && (
                     <Text
                        className="menupage-header-text flex flex-col gap-1"
                        size="1.3rem"
                        color="#0F191A"
                        lHeight="1"
                        align="left">
                        {
                           <>
                              <span className="font-custom font-bold">{item === "hourly" ? "Hourly Specials" : item === "daily" ? "Daily Specials" : CATEGORY_NAME}</span>
                              <span
                                 className="text-[#858165] font-custom"
                                 style={{
                                    fontSize: "1rem",
                                 }}>
                                 {allItems?.items?.length} items
                              </span>
                           </>
                        }
                     </Text>
                  )}
                  {!merchantDetails.show_menu_only && ordersCondition && (
                     <div
                        onClick={onClick}
                        className="ring-1 rounded-[50px] ring-[#C3C5C6] font-custom p-1">
                        <CartIcon count={cartCount} />
                     </div>
                  )}
               </div>
            </div>
            <div className="sticky px-3 py-2 top-0 z-[10] w-[100%]">
               <SearchInput
                  showCrossEndAdornment={showSearchResult}
                  searchValue={searchValue}
                  setSearchValue={handleShowSearchPage}
               />
            </div>
            <div className={classNames(cartCount > 0 ? "pb-[80px]" : "", "menupage-container  position-relative ")}>
               <div className="menupage-filter-btns-subcontainer px-3 w-[100%]">
                  {FILTER_DATA.map((filter) => (
                     <ToggleChipWithSwitch
                        disabled={filter.disableOn && selectedType[filter.disableOn as keyof FilterProps]}
                        selected={selectedType[filter.id as keyof FilterProps]}
                        onClickHandler={() => handleFilterChange(filter.id as keyof FilterProps, !selectedType[filter.id as keyof FilterProps])}
                        label={filter.label}
                     />
                  ))}
               </div>

               <>
                  <div className="menupage-item-container flex flex-col items-center">
                     {result.data?.items?.length > 0 ? (
                        result.data?.items?.filter(filterDataBasedOnValues).map((item: any, index: any) => (
                           <div className="px-3 w-[100%]">
                              <ItemCard
                                 cardType="details"
                                 mid={mid ?? ""}
                                 item={item}
                                 handleShowDetails={handleShowDetails}
                                 key={item.item_id}
                              />
                           </div>
                        ))
                     ) : (
                        <NoItems />
                     )}
                  </div>
               </>
            </div>

            {ordersCondition && showViewCartButton && (
               <div>
                  <ViewCartButton
                     buttonTitle={"View cart"}
                     onClickButton={onClickViewCart}
                  />
               </div>
            )}
         </>
      </div>
   );
};

export default MenuPage;
