/** @format */

import { useParams } from "react-router-dom";
import YellowArrowIcon from "../../asset/icons/YellowArrowIcon";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppSelector } from "../../store/hooks";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import "./style.scss";
import { Slide } from "@mui/material";
import showMenuSelector from "../../store/showMenu/selectors";
import { useApp } from "src/store/useApp";

const ViewCartButton = ({ buttonTitle, onClickButton }: any) => {
   const { merchantDetails, getCartTotal, getCart } = useApp();
   const { currency } = merchantDetails;
   const count = getCart().length;

   return (
      <Slide
         in={true}
         direction="up">
         <div className={`view-cart-btn-container ${buttonTitle === "Confirm" && ""}`}>
            <div>
               <div className="view-cart-item-count-wrapper font-custom">{count > 1 ? "items" : "item"}</div>
               <div className="view-cart-price-wrapper font-custom">
                  {currency}
                  {getCartTotal()}
               </div>
            </div>
            <div>
               <div
                  onClick={onClickButton}
                  className="flex items-center justify-center gap-2">
                  <div className="view-cart-btn font-custom">{buttonTitle}</div>
                  <YellowArrowIcon />
               </div>
            </div>
         </div>
      </Slide>
   );
};

export default ViewCartButton;
