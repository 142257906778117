/** @format */

import MuiAlert from "@mui/material/Alert";
import Sucess from "canvas-confetti";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useApp } from "src/store/useApp";
import verify from "../../../asset/svg/OffersScreen/verified.svg";
import { ReactComponent as OfferArrowDown } from "../../../asset/svg/OrderSummary/OfferArrowDown.svg";
import { ReactComponent as OfferArrowUp } from "../../../asset/svg/OrderSummary/OfferArrowUp.svg";
import Warning from "../../../asset/svg/OrderSummary/Warning.svg";
import AcknownledgementModal from "../../../component/OrderSummary/AcknownledgementModal";
import OrderDividerTitle from "../../../component/OrderSummary/OrderDividerTitle";
import OrderItemsRow from "../../../component/OrderSummary/OrderItemsRow";
import OrderPageHeader from "../../../component/OrderSummary/OrderPageHeader";
import OrderStatusIndicator from "../../../component/OrderSummary/OrderStatusIndicator";
import SubOrderCard from "../../../component/OrderSummary/SubOrderCard";
import TotalAmountToPay from "../../../component/OrderSummary/TotalAmountToPay";
import { useAppNavigate } from "src/hooks/useAppNavigate";

function OrderStatusPage({ orderId, orderDetails, orderPlacedModal, NavigateToOrderStatusPage, NavigateToOrderDetailsPage, setorderPlacedModal, onAddMoreItems, RetryPayment }) {
   const [open, setOpen] = useState(false);
   const [showAlert, setshowAlert] = useState(false);
   const { merchantDetails, setAddMoreItemFlow, setExistingOrder } = useApp();
   const { currency } = merchantDetails;

   const appNavigate = useAppNavigate();

   const location = useLocation();
   console.log(location?.state?.fromDinein, "LOCATIONSTATE");
   const navigate = useNavigate();
   const { mid, qid } = useParams();
   const Alert = React.forwardRef(function Alert(props, ref) {
      return (
         <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
         />
      );
   });

   useEffect(() => {
      if (orderPlacedModal) {
         Sucess({
            particleCount: 200,
            spread: 40,
            origin: { y: 0.6 },
         });
      }
   }, [orderPlacedModal === true]);

   const addItemHandler = () => {
      if (
         orderDetails?.items_ordered[orderDetails?.items_ordered.length - 1]?.suborder_status !== "new_order" ||
         (orderDetails?.items_ordered[orderDetails?.items_ordered.length - 1]?.suborder_status !== "new_order" && orderDetails?.order_status !== "order_completed")
      ) {
         setAddMoreItemFlow();
         appNavigate({
            path: "",
         });
         setExistingOrder(orderDetails);
      } else {
         setshowAlert(true);
      }
   };

   console.log("Steps", orderDetails);

   const OfferHandler = () => {
      setOpen(!open);
   };

   const totalItemsCount = (order) => {
      let totalAmount = 0;
      let totalOffersDiscount = 0;
      let totalLoyaltyDiscount = 0;

      order.items_ordered?.forEach((suborder) => {
         suborder.suborder_items_ordered?.forEach((item) => {
            totalAmount += item.total_price;
         });

         totalOffersDiscount += suborder.offers_discount_amount;
         totalLoyaltyDiscount += suborder.loyalty_discount_amount;
      });

      return { totalAmount, totalOffersDiscount, totalLoyaltyDiscount };
   };

   const handleBackArrow = () => {
      const fromCheckout = JSON.parse(localStorage.getItem("fromCheckout"));
      if (fromCheckout) {
         localStorage.removeItem("fromCheckout");
         appNavigate({ path: "" });
      } else {
         navigate(-1);
      }
   };

   return (
      <>
         {orderPlacedModal && (
            <AcknownledgementModal
               open={orderPlacedModal}
               orderModal={true}
               onClose={() => setorderPlacedModal(false)}
               icon={verify}
               statementPrimary={"Order completed successfully!"}
               buttonName={`Close`}
            />
         )}

         <Toaster
            position="top-center"
            containerStyle={{ zIndex: 9999, marginTop: `3rem` }}
         />
         <ToastContainer
            hideProgressBar={true}
            position="bottom-center"
            limit={1}
            style={{ zIndex: 9999 }}
            bodyStyle={{
               zIndex: 11111,
               marginBottom: `25%`,
            }}
         />
         {orderDetails?.order_type === "Take away" || orderDetails?.order_status === "order_completed" ? (
            <div>
               <OrderPageHeader
                  title={"Order summary"}
                  orderId={orderDetails?.order_id}
                  addItemsButton={true}
                  HandlerBackArrow={handleBackArrow}
               />
               <OrderStatusIndicator
                  order_status={orderDetails.order_status}
                  completeStatus={orderDetails}
                  indicator={true}
               />
               <OrderDividerTitle title={"Order details"} />
               <div className="px-2">
                  {orderDetails?.items_ordered?.map((order) => (
                     <>
                        {order?.suborder_items_ordered?.map((suborder, index) => (
                           <OrderItemsRow
                              key={index}
                              itemDetails={suborder}
                           />
                        ))}
                     </>
                  ))}
               </div>
               <div className="px-2 pb-3">
                  <div className="flex justify-between items-center mt-2 mb-1">
                     <span className="text-sm font-custom">Total items</span>
                     <span className="text-sm font-custom">{orderDetails?.items_ordered?.length}</span>
                  </div>
                  <div className="flex justify-between items-center mb-1">
                     <span className="text-sm font-custom">Sub total</span>
                     <span className="text-sm font-custom">
                        {currency}
                        {totalItemsCount(orderDetails).totalAmount}
                     </span>
                  </div>
                  {totalItemsCount(orderDetails).totalOffersDiscount > 0 && (
                     <div
                        className="flex justify-between items-center mb-1"
                        onClick={OfferHandler}>
                        <span className="text-sm flex items-center gap-1 font-custom">Offers {open ? <OfferArrowUp /> : <OfferArrowDown />}</span>
                        <span className="text-sm text-[#2FBF71] font-custom">
                           -{currency}
                           {totalItemsCount(orderDetails).totalOffersDiscount}
                        </span>
                     </div>
                  )}

                  {open && (
                     <div className="pl-3">
                        {totalItemsCount(orderDetails).totalOffersDiscount > 0 && (
                           <div className="flex justify-between items-center mt-2 mb-1">
                              <span className="text-sm font-custom">Offer discount</span>
                              <span className="text-sm font-custom">
                                 -{currency}
                                 {totalItemsCount(orderDetails).totalOffersDiscount}
                              </span>
                           </div>
                        )}
                        {totalItemsCount(orderDetails).totalLoyaltyDiscount > 0 && (
                           <div className="flex justify-between items-center mt-2 mb-1">
                              <span className="text-sm font-custom">Loyalty discount</span>
                              <span className="text-sm font-custom">
                                 -{currency}
                                 {totalItemsCount(orderDetails).totalLoyaltyDiscount}
                              </span>
                           </div>
                        )}
                     </div>
                  )}
                  <div className="flex justify-between items-center py-3 border-b border-t">
                     <span className="text-base font-semibold font-custom">Total price</span>
                     <span className="text-base font-semibold font-custom">
                        {currency}
                        {orderDetails?.amount_after_taxes}
                     </span>
                  </div>
               </div>
            </div>
         ) : (
            <>
               {orderDetails?.order_type === "Dine-in" || orderDetails?.order_type === "Take away" ? (
                  <>
                     <div className="flex justify-between items-center pr-2.5">
                        <OrderPageHeader
                           title={"Order summary"}
                           orderId={orderDetails?.order_id}
                           addItemsButton={true}
                           HandlerBackArrow={handleBackArrow}
                        />

                        {orderDetails.order_type === "Dine-in" && !["order_completed", "cancelled"].includes(orderDetails.order_status) && (
                           <button
                              className="text-base text-[#0F6656] font-custom font-semibold cursor-pointer"
                              disabled={orderDetails?.order_status === "order_completed"}
                              onClick={addItemHandler}>
                              + Add items
                           </button>
                        )}
                     </div>

                     <TotalAmountToPay amount={orderDetails?.amount_after_taxes} />
                     <SubOrderCard orderDetails={orderDetails} />
                     <AcknownledgementModal
                        open={showAlert}
                        onClose={() => setshowAlert(false)}
                        icon={Warning}
                        statementPrimary={"Items cannot be added until the restaurant confirms your existing order."}
                        buttonName={`Got it!`}
                     />
                  </>
               ) : (
                  ""
               )}
            </>
         )}
      </>
   );
}

export default OrderStatusPage;
