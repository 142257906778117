import { width } from '@mui/system'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
function OrderDetailsSkeleton() {
  return (
    <div className='order-details-skeleton-container' >
      <div className='order-details-skeleton-subcontainer' >
        <Skeleton height={"3.5rem"} width={"3.5rem"} />
        <Skeleton width={"93%"} count={3} />
      </div>
      <Skeleton style={{ marginTop: "0rem" }} width="95%" />
    </div>
  )
}

export default OrderDetailsSkeleton