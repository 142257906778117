import React from "react";
import Skeleton from "react-loading-skeleton";

interface SingleOrderDetailsPageSkeletonProps {
  isVisible: boolean;
}

const SingleOrderDetailsPageSkeleton: React.FC<
  SingleOrderDetailsPageSkeletonProps
> = ({ isVisible }) => {
  return (
    <div
      className="w-screen p-2 h-screen   fixed bg-white z-10"
      hidden={!isVisible}
    >
      <Skeleton
        height={"5%"}
        width={"100%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginTop: "15%",
        }}
      />
      <Skeleton
        height={"15%"}
        width={"100%"}
        style={{
          alignSelf: "center",
          display: "flex",
        }}
      />
      <Skeleton
        height={"50%"}
        width={"100%"}
        style={{
          alignSelf: "center",
          display: "flex",
        }}
      />
    </div>
  );
};

export default SingleOrderDetailsPageSkeleton;
