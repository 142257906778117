/** @format */

import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CoinIcon from "../../asset/icons/CoinIcon";
import bg from "src/asset/images/bg.png";
import ClubIcon from "../../asset/icons/ClubIcon";
import { useApp } from "src/store/useApp";

type Props = {
   appliedCoins: any;
   previewDesc: string;
   onClick: any;
   custom?: boolean;
};

function LoyaltyBanner({ appliedCoins, previewDesc, onClick, custom }: Props) {
   const { loyalty_discount_amount, loyalty_points_used, merchantDetails, subscribedToLoyalty } = useApp();
   const { currency } = merchantDetails;

   return (
      <>
         {custom ? (
            <>
               <div
                  className="apply-coupon-container mt-2 bg-[#FDF0C2] border-none text-[#F98200] h-[55px] w-full"
                  onClick={onClick}>
                  <div className="apply-coupon-wrapper d-flex align-items-center justify-content-center w-100">
                     {subscribedToLoyalty && appliedCoins ? (
                        <>
                           <CoinIcon />
                           <div className="ms-2 ">
                              <div
                                 className="fw-bold font-custom"
                                 style={{ fontSize: "0.8rem", color: "#2FBF71" }}>
                                 {previewDesc}
                              </div>
                              <div className="fw-bold font-custom ">Loyalty points: {appliedCoins}</div>
                           </div>
                        </>
                     ) : (
                        <div className="w-100 fw-bold w-[290px] text-[15px] flex gap-2 justify-center items-center">
                           <CoinIcon />
                           <span>Subscribe to loyalty program</span>
                        </div>
                     )}
                  </div>
                  {!subscribedToLoyalty && <ArrowForwardIosIcon sx={{ color: "#FF4700" }} />}
               </div>
               {appliedCoins < 1000 && <div className="text-xs font-normal text-center w-full text-[#F98200]">“You will be eligible for a discount once you have accumulated 1000 loyalty points”</div>}
            </>
         ) : (
            <div
               className={`apply-coupon-container mt-2 bg-center bg-no-repeat bg-cover border-none h-[60px]`}
               style={{
                  backgroundImage: `url(${bg})`,
               }}
               onClick={onClick}>
               <div className="apply-coupon-wrapper w-100">
                  {appliedCoins ? (
                     <>
                        <CoinIcon />
                        <div className="ms-2 ">
                           <div className="fw-bold">{appliedCoins}</div>
                           <div
                              className="mt-1 fw-bold"
                              style={{ fontSize: "0.8rem", color: "#2FBF71" }}>
                              {`${currency}${loyalty_discount_amount} Off applied using ${loyalty_points_used} coins`}
                           </div>
                        </div>
                     </>
                  ) : subscribedToLoyalty ? (
                     <div className="flex text-[#FF4700] items-center gap-2 apply-coupon-text fw-bold text-[15px]">
                        <span>
                           <ClubIcon />
                        </span>
                        <span>Apply discount using your loyalty coins</span>
                     </div>
                  ) : (
                     <div className="w-100 text-[#FF4700] w-[290px] text-[17px] flex gap-[5px] justify-center items-center">
                        <span>
                           <ClubIcon />
                        </span>
                        <span className="text-[#FF4700]">
                           Join <b>Exclusive Club</b> & Earn benefits
                        </span>
                     </div>
                  )}
               </div>
               {subscribedToLoyalty && <ArrowForwardIosIcon sx={{ color: "#FF4700" }} />}
            </div>
         )}
      </>
   );
}

export default LoyaltyBanner;
