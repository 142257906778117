/** @format */

import { useEffect } from "react";
import "aos/dist/aos.css";
import Providers from "./providers";
import AllRoutes from "./routes";

const App = () => {
  const removeConsole = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      console.log = () => {};
    }
  };

  useEffect(() => {
    removeConsole();
  }, []);

  return (
    <Providers>
      <AllRoutes />
    </Providers>
  );
};

export default App;
