import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "DM Sans",
        },
        h4: {
            fontWeight: 600,
            color: "#000",
            marginBottom: "2%",
        },
        h2: {
            fontWeight: 700,
            color: "#000",
        },
        body1: {
            fontWeight: 500,
            color: "#878C8C",
            fontSize: "1.2rem",
        },
    },
});

theme = responsiveFontSizes(theme);

export { theme }