import react from "react";
import { ArrowRight } from "react-feather";
import "./style.scss";

interface CategoryCardProps {
  text: string;
  link: string;
  background: string;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  background,
  link,
  text,
}) => {
  const styles = {
    background: background,
    fontSize: text?.length > 30 ? "1.1rem" : "1.4rem",
  };
  return (
    <div style={styles} className="menu-card-item-container font-custom">
      {text}
      <div className="menu-card-item-arrow-wrapper">
        <ArrowRight />
      </div>
    </div>
  );
};

export default CategoryCard;
