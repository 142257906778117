/** @format */

import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

type NavigateOptions = {
   path: string;
};

export const useAppNavigate = () => {
   const navigate = useNavigate();
   const params = useParams();

   const navigateTo = useCallback(
      ({ path }: NavigateOptions) => {
         const currentMid = params.mid;
         const currentQid = params.qid;

         if (!currentMid) {
            console.error("Missing mid parameter");
            return;
         }

         const url = currentQid ? `/${currentMid}/${currentQid}${path}` : `/${currentMid}${path}`;
         navigate(url);
      },
      [navigate, params],
   );

   return navigateTo;
};
