/** @format */

import { Order, OrderType, SubOrder } from "src/interface/order";
import { getBillDetails, getOverallLoyaltyAmount, getOverallOfferAmount } from "./helper";
import { useAppProps, useOrderProps } from "./storeTypes";

type SetState = (partial: Partial<useAppProps> | ((state: useAppProps) => Partial<useAppProps>), replace?: boolean) => void;

export const useOrder = (set: SetState, get: () => useAppProps): useOrderProps => ({
   order: {
      amount_after_taxes: 0,
      items_ordered: [] as Array<SubOrder>,
      total_amount: 0,
   } as Order,
   orderType: "Dine-in",
   setOrderType: (orderType: OrderType) => {
      set({ orderType });
   },
   setExistingOrder: (order: Order) => {
      set({ order });
   },
   generateSubOrder: () => {
      const { appliedOffer, loyalty_discount_amount, loyalty_points_used, getCart, getCartTotal, getOfferAppliedAmount } = get();

      const total_suborder_amount = getCartTotal();
      const offers_discount_amount = getOfferAppliedAmount();
      const total_suborder_amount_offers_applied = total_suborder_amount - (loyalty_discount_amount + offers_discount_amount);
      const offers_applied = [appliedOffer];

      let subOrder: SubOrder = {
         suborder_items_ordered: getCart(),
         loyalty_discount_amount,
         loyalty_points_used,
         offers_applied,
         offers_discount_amount,
         suborder_status: "new_order",
         total_suborder_amount,
         total_suborder_amount_offers_applied,
      };

      return subOrder;
   },
   getOrderPayload: (qr_code_id: string) => {
      let { orderType, merchantDetails, addMoreItemFlow, order, generateSubOrder } = get();
      const { merchant_id } = merchantDetails;

      let { items_ordered } = order;
      console.log(items_ordered, "getOrderPayload");

      items_ordered = addMoreItemFlow ? [...items_ordered, generateSubOrder()] : [generateSubOrder()];

      let overall_loyalty_amount = getOverallLoyaltyAmount(items_ordered);
      let overall_offers_amount = getOverallOfferAmount(items_ordered);

      const { amount_after_taxes, cgst_percentage, sgst_percentage, rounded_off, total_amount, order_discount, total_amount_after_discount } = getBillDetails(items_ordered, merchantDetails);

      let orderPayload: Order = {
         ...order,
         items_ordered,
         merchant_id,
         payment_status: "un-paid",
         order_status: addMoreItemFlow ? "order_updated" : "new_order",
         order_type: orderType,
         qr_code_id: qr_code_id !== undefined ? qr_code_id : "standalone",
         overall_loyalty_amount,
         overall_offers_amount,
         refund: 0,
         amount_after_taxes,
         cgst_percentage,
         sgst_percentage,
         rounded_off,
         total_amount,
         order_discount,
         total_amount_after_discount,
         payment_order_id: "",
      };
      return orderPayload;
   },
   resetOrder: () => {
      set({
         order: {
            amount_after_taxes: 0,
            items_ordered: [] as Array<SubOrder>,
            total_amount: 0,
         } as Order,
         orderType: "Dine-in",
      });
   },
});
