/** @format */

import { Add, Remove } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AddOnItem, Item } from "src/interface/item";
import { useApp } from "src/store/useApp";
import DiscountRect from "../../asset/icons/DiscountRect";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import { useAppSelector } from "../../store/hooks";
import { classNames } from "../../utils/classNames";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";
import AddonsModal from "../AddOnsModal";
import DetailsModal from "../DetailsModal";
import NonVegTag from "../NonVegTag";
import "./style.scss";

interface ItemCardProps {
   cardType: string;
   mid: string;
   item: Item;
   handleShowDetails?: (data: any) => void;
}

const ItemCard = ({ cardType, mid, item }: ItemCardProps) => {
   console.log(item);
   const {
      image_url: imageSrc,
      item_name: dishName,
      description,
      price,
      price_after_discount,
      discount,
      type,
      item_id,
      out_of_stock,
      recommended,
      best_selling: bestSelling,
      merchant_id,
      remaining_quantity,
      manage_inventory,
      count,
   } = item;
   const { addMoreItemFlow, getCart, addOrRemoveItem, merchantDetails, getCartItem, resetOffer } = useApp();

   const outOfStock = out_of_stock;
   const { show_menu_only: showMenuOnly, show_menu_with_order: showMenuWithOrder, accept_orders: acceptOrders, show_available_item_count, currency } = merchantDetails;
   const [showItemDetails, setshowItemDetails] = useState(false);
   const [showAddons, setShowAddons] = useState(false);
   const cartItem = getCartItem(item_id);

   const addedMoreItem = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItem(item_id));

   const canReduceItem = useAppSelector(addMoreItemsOnCartSelector.canReduceItem(item_id));

   const isItemAlreadyAdded = () => {
      if (addMoreItemFlow && addedMoreItem?.count !== undefined) {
         return true;
      } else if (cartItem?.count !== undefined) {
         return true;
      }
      return false;
   };

   const handleAddToCart = () => {
      const cookies = Object.fromEntries(document.cookie.split("; ").map((v) => v.split(/=(.*)/s).map(decodeURIComponent)));
      const isQuantityReached = item?.manage_inventory ? cartItem?.count === remaining_quantity : false;

      if (isQuantityReached) {
         toast.error("No more quantity available");
      } else {
         addOrRemoveItem(item, "add");
      }

      //@ts-ignore
      if (((item?.add_ons && !item.add_ons.includes(null) && item?.add_ons?.length > 0) || (item.customizations && item?.customizations?.length > 0)) && !isItemAlreadyAdded()) {
         setShowAddons(true);
      }
   };

   const showDetailsButton = () => {
      if (item?.description !== "") return true;
      if (item?.ingredients !== undefined && item?.ingredients !== "") return true;
      return false;
   };

   const Price = () => {
      return (
         <div className={classNames(cardType === "details" ? "justify-end" : "justify-end", "food-item-before-order-price-wrapper font-custom items-center w-[100%] ")}>
            {!outOfStock ? (
               <>
                  <div className="food-item-before-order-price-content1 font-custom">{`${currency}${price_after_discount}`}</div>
                  {discount === 0 ? "" : <div className="food-item-before-order-price-content2 font-custom">{`${currency}${price}`}</div>}
               </>
            ) : (
               <div className="food-item-before-order-out-of-stock-text font-custom">Out of stock</div>
            )}
         </div>
      );
   };

   const handleRemoveFromCart = (count: number) => {
      addOrRemoveItem(item, "remove");
   };

   const style1 = {
      opacity: outOfStock ? 0.5 : 1,
   };

   return (
      <div style={style1}>
         <AddonsModal
            visible={showAddons}
            onClose={() => setShowAddons(false)}
            item={item}
         />
         <DetailsModal
            item={item}
            visible={showItemDetails}
            onClose={() => setshowItemDetails(false)}
            updateToggle={undefined}
            getPeopleAlsoOrdered={undefined}
            mid={undefined}
         />
         <div className="food-item-before-order-container">
            <div className="food-item-before-order-left-section !relative">
               {discount > 0 && (
                  <div className="food-item-before-order-btns-discount-container">
                     <DiscountRect />
                     <div
                        className="font-custom"
                        style={{
                           position: "absolute",
                           top: 3,
                           color: "white",
                           fontSize: "0.9rem",
                           left: 2,
                        }}>
                        {discount}% off
                     </div>
                  </div>
               )}
               {imageSrc && imageSrc !== "null" ? (
                  <img
                     className="food-item-before-order-image-wrapper h-[5.3rem]"
                     src={imageSrc}
                  />
               ) : (
                  <div className="food-item-before-order-dish-name-container font-custom">{dishName[0]}</div>
               )}
            </div>
            <div className="food-item-before-order-right-section">
               <div className="food-item-before-order-right-middle-section mt-3">
                  <NonVegTag veg={type === "veg"} />
               </div>
               <div className="flex justify-between ">
                  <div className="w-[100%] gap-2 flex flex-col mt-2">
                     <div className="food-item-before-order-title-wrapper">
                        <div className="font-custom food-item-before-order-dish-name-content   mb-1">{capitalizeFirstLetter(dishName)}</div>
                     </div>

                     {cardType === "details" && (
                        <div
                           style={{
                              display: showDetailsButton() ? "flex" : "none",
                              width: "60px",
                              alignItems: "center",
                           }}
                           onClick={() => setshowItemDetails(true)}
                           className=" text-[0.8rem] rounded-[0.8rem] bottom-[-1.9rem] text-center ">
                           <span>Details</span>
                           <ChevronRightIcon style={{ fontSize: "0.8rem" }} />
                        </div>
                     )}
                     <div className="food-item-before-order-info-container">
                        {show_available_item_count && manage_inventory && <div className="available-items">Available items : {remaining_quantity}</div>}
                     </div>
                  </div>
                  <div className={`flex flex-col gap-1.5 mt-[7px] {relative}`}>
                     <span className="mr-2 mb-1">
                        <Price />
                     </span>
                     {!showMenuOnly && (
                        <div className={classNames(cardType === "details" ? "h-[50px] flex justify-end items-end" : "", "mt-1 ")}>
                           {getCart().length > 0 ? (
                              <div className="food-item-before-order-count-container">
                                 <Remove
                                    sx={{ color: "#0f6656" }}
                                    fontSize="small"
                                    onClick={() => {
                                       canReduceItem && handleRemoveFromCart(cartItem?.count as number);
                                    }}
                                 />
                                 {cartItem?.count || 0}
                                 <Add
                                    sx={{ color: "#0f6656" }}
                                    fontSize="small"
                                    onClick={handleAddToCart}
                                 />
                              </div>
                           ) : acceptOrders && !outOfStock && showMenuWithOrder ? (
                              <div
                                 className="food-item-before-order-add-button"
                                 onClick={handleAddToCart}>
                                 Add
                              </div>
                           ) : null}
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ItemCard;
