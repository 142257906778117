import { request } from "../axios";
import {
  SuccessResponse,
  ErrorResponse,
  OrderResponsePayload,
} from "../index.type";
import { PayloadProps, Props } from "./models/createOrder.type";
import { updateOrderProps } from "./models/updateOrder.type";
import { ErrorResponseHandler } from "../index";
import ConfirmOrderModal from "../../pages/CheckoutPage/TotalAmountDetails";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";

const updateOrderAPI = async (
  existingOrder: updateOrderProps,
  accessToken: string
): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  try {
    const response = await request<OrderResponsePayload<PayloadProps>>({
      accessToken,
      api: `/customer/order/${existingOrder.order_id}`,
      method: "PUT",

      data: existingOrder,
    });

    const result = response.data;

    if (response.status > 300) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: "Success",
      payload: result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default updateOrderAPI;
