/** @format */
import { EndPoints as EndPointsProps } from "./interface";

export const EndPoints: EndPointsProps = {
   menuEndPoints: {
      Menu: (merchant_id: string) => `/customer/menu/${merchant_id}`,
      BestSellingItems: (merchant_id: string) => `/customer/best_selling_items/${merchant_id}`,
      RecommendedItems: (merchant_id: string) => `/customer/recommended_items/${merchant_id}`,
      NewlyAddedItems: (merchant_id: string) => `/customer/newly_added_items/${merchant_id}`,
      CategoryItems: (category_id: string) => `/category_items/${category_id}`,
      DailySpecials: (merchant_id: string) => `/customer/specials/current_daily_specials/${merchant_id}`,
      HourlySpecials: (merchant_id: string) => `/customer/specials/current_hourly_specials/${merchant_id}`,
   },
   customerEndPoints: {
      CountVisits: () => "",
      CustomerDetails: "customer/customer_details",
   },
   loyaltyEndPoints: {
      LoyaltyDetails: (merchant_id: string) => `/customer/loyalty/subscribe/${merchant_id}`,
      LoyaltyPoints: (merchant_id: string) => `/customer/loyalty/points/${merchant_id}`,
   },
   merchantEndpoints: {
      MerchantDetails: (merchant_id: string) => `/customer/merchant_details/${merchant_id}`,
   },
   offersEndPoints: {
      Offers: (merchant_id: string) => `/offers/${merchant_id}`,
   },
   orderEndPoints: {
      CreateOrder: "/customer/order/",
      AllOrders: (merchant_id: string) => `/customer/order/all/${merchant_id}`,
      Order: (orderId: string) => `/customer/order/${orderId}`,
   },
};
