/** @format */

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BottomModal from "../../component/BottomModal";
import FilterModal from "../../component/FilterModal";
import SearchInput from "../../component/SearchInput";
import { Text } from "../../component/Text";
// import OfferCard from "../../component/OfferCard";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { useQueries } from "@tanstack/react-query";
import { isSupported } from "firebase/messaging";
import "react-loading-skeleton/dist/skeleton.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { EndPoints } from "src/api/endPoints";
import { GetAPI } from "src/api/request";
import ItemsTab from "src/component/ItemsTab";
import HomePageSkeleton from "src/component/Skeletons/HomePageSkeleton";
import { TABS } from "src/constants/menuTabs";
import { Offer } from "src/interface/offer";
import { useApp } from "src/store/useApp";
import CartIcon from "../../asset/icons/CartIcon";
import DrawerIcon from "../../asset/icons/DrawerIcon";
import CustomDrawer from "../../component/Drawer";
import HourlyDailyCard from "../../component/HourlyDailyCard";
import ItemTabContent from "../../component/ItemTabContent";
import OffersBanner from "../../component/OffersBanner";
import WarningModal from "../../component/WariningModal";
import hourlyCardImage from "./../../asset/images/choel bature.png";
import { featuredItemsTransformer, menuDataTransformer } from "./helper";
import "./style.scss";

export interface TabData {
   all: Array<any>;
   newlyAddedItems: Array<any>;
   recommendedItems: Array<any>;
   bestSellingItems: Array<any>;
}

const HomePage = () => {
   const { merchantDetails, addMoreItemFlow, resetAddMoreItemFlow, setMerchantDetails, getCart, resetCart, setOffers, setLoyaltyDetails, setLoyaltyAvailableCoins } = useApp();
   const counterRef = useRef(0);
   const { mid, qid } = useParams<{ mid: string; qid: string }>();
   const messagingSupported = isSupported();
   const location = useLocation();
   const [currentTab, setCurrentTab] = useState<keyof TabData>("all");
   const [openDrawer, setOpenDrawer] = useState(false);

   // state declarations
   const [openFilter, setOpenFilter] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const [isNotificationEnabled, setisNotificationEnabled] = useState(false);
   const [showSearchResult, setShowSearchResult] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
      if (counterRef.current === 0) {
         counterRef.current = 1;
      }
      localStorage.getItem("redirect");
   }, []);

   const handleShowSearchPage = (value: string | any) => {
      setCurrentTab("all");
      setSearchValue(value);
   };

   const toggleDrawer = () => {
      setOpenDrawer(!openDrawer);
   };

   const ORDER = location.state?.orderDetails;

   const onClickCart = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   const openReferral = () => {
      window.open("https://forms.gle/27Z8ofFLJi8mNhVx5");
   };

   const navigateToHourlySpecials = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/menu/hourly`);
      } else {
         navigate(`/${mid}/menu/hourly`);
      }
   };

   const navigateToDailySpecials = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/menu/daily`);
      } else {
         navigate(`/${mid}/menu/daily`);
      }
   };

   useEffect(() => {
      messagingSupported.then((supported) => {
         if (supported) {
            if (Notification.permission === "granted") {
               setisNotificationEnabled(true);
            } else {
               setisNotificationEnabled(false);
            }
         }
      });
   }, []);

   const fetchMenu = () => {
      return GetAPI(EndPoints.menuEndPoints.Menu(mid as string)).then((res: any) => {
         return res?.data;
      });
   };

   const fetchBestSellingItems = () => {
      return GetAPI(EndPoints.menuEndPoints.BestSellingItems(mid as string)).then((res: any) => res?.data?.best_selling_items);
   };

   const fetchNewlyAddedItems = () => {
      return GetAPI(EndPoints.menuEndPoints.NewlyAddedItems(mid as string)).then((res: any) => res?.data?.newly_added_items);
   };

   const fetchRecommendedItems = () => {
      return GetAPI(EndPoints.menuEndPoints.RecommendedItems(mid as string)).then((res: any) => res?.data?.recommended_items);
   };

   const fetchAllOffers = () => {
      return GetAPI(EndPoints.offersEndPoints.Offers(mid as string)).then((res: any) => {
         setOffers();
         return res?.data;
      });
   };

   const fetchMerchantDetails = () => {
      return setMerchantDetails(mid as string);
   };

   const fetchLoyaltySubscriptionDetails = () => {
      setLoyaltyDetails();
      setLoyaltyAvailableCoins();
   };

   const fetchDailySpecials = () => {
      return GetAPI(EndPoints.menuEndPoints.DailySpecials(mid as string)).then((res: any) => {
         return res?.data;
      });
   };

   const fetchHourlySpecials = () => {
      return GetAPI(EndPoints.menuEndPoints.HourlySpecials(mid as string)).then((res: any) => {
         return res?.data;
      });
   };

   const result = useQueries({
      queries: [
         {
            queryKey: ["menu", mid],
            queryFn: fetchMenu,
         },
         {
            queryKey: ["newlyAddedItems", mid],
            queryFn: fetchNewlyAddedItems,
         },
         {
            queryKey: ["recommendedItems", mid],
            queryFn: fetchRecommendedItems,
         },
         {
            queryKey: ["bestSellingItems", mid],
            queryFn: fetchBestSellingItems,
         },
         {
            queryKey: ["offers", mid],
            queryFn: fetchAllOffers,
         },
         {
            queryKey: ["merchant", mid],
            queryFn: fetchMerchantDetails,
         },
         {
            queryKey: ["loyalty", mid],
            queryFn: fetchLoyaltySubscriptionDetails,
         },
         {
            queryKey: ["dailySpecials", mid],
            queryFn: fetchDailySpecials,
         },
         {
            queryKey: ["hourlySpecials", mid],
            queryFn: fetchHourlySpecials,
         },
      ],
   });

   const tabData = {
      all: menuDataTransformer(result[0]?.data),
      newlyAddedItems: featuredItemsTransformer(result[1]?.data, "from_newly_added_items"),
      recommendedItems: featuredItemsTransformer(result[2]?.data, "from_recommended_items"),
      bestSellingItems: featuredItemsTransformer(result[3]?.data, "from_best_selling_items"),
   };

   console.log("MENU RESULT !!", result[0].data);

   const specials = {
      hourly: result[8]?.data || [],
      daily: result[7]?.data || [],
   };

   const offers: Array<Offer> = result[4]?.data?.offers;

   const isMoreThanOneSpecial = () => Object.values(specials)?.filter((item) => item)?.length > 0;

   const isNoAcceptOrders = merchantDetails.plan_active && merchantDetails.show_menu_with_order && !merchantDetails.accept_orders;

   const ordersCondition = merchantDetails.show_menu_with_order && merchantDetails.accept_orders;

   const cartCount = getCart()?.length;

   return (
      <>
         <HomePageSkeleton isVisible={result[8].isLoading} />
         <div
            className="w-full h-full"
            hidden={result[8].isLoading}>
            <CustomDrawer
               open={openDrawer}
               toggleDrawer={toggleDrawer}
            />
            <div className="homepage-container">
               <WarningModal />
               <div className="homepage-top-section">
                  <div className="homepage-header">
                     <div>
                        {!addMoreItemFlow && (
                           <div
                              onClick={toggleDrawer}
                              className="drawer-icon-wrapper">
                              <DrawerIcon />
                           </div>
                        )}
                     </div>
                     <div className="homepage-header-text">
                        <div className="homepage-text1 font-custom">{merchantDetails?.restaurant_name}</div>
                     </div>

                     <div>
                        {!merchantDetails.show_menu_only && ordersCondition ? (
                           <div
                              className="ring-1 rounded-[50px] ring-[#C3C5C6] p-1"
                              onClick={onClickCart}>
                              <CartIcon count={cartCount} />
                           </div>
                        ) : (
                           <div style={{ marginLeft: "3.5rem" }}></div>
                        )}
                     </div>
                  </div>

                  {isNoAcceptOrders ? (
                     <div>
                        <Text className="eatery-text">The eatery is not currently accepting orders</Text>
                     </div>
                  ) : null}

                  {addMoreItemFlow && (
                     <Alert
                        sx={{ marginBottom: "1rem" }}
                        action={
                           <Button
                              onClick={resetAddMoreItemFlow}
                              color="inherit"
                              size="large">
                              Cancel
                           </Button>
                        }
                        severity="info">
                        <AlertTitle>Adding items</AlertTitle>
                        You are adding more items to order Id <strong> {ORDER?.order_id}</strong>
                     </Alert>
                  )}
                  <div className="sticky top-0">
                     {tabData.all?.length > 0 && (
                        <SearchInput
                           showCrossEndAdornment={showSearchResult}
                           searchValue={searchValue}
                           setSearchValue={(value) => handleShowSearchPage(value)}
                        />
                     )}
                  </div>
               </div>
               {offers?.length > 0 && searchValue.length === 0 && <OffersBanner offers={offers} />}
               {merchantDetails !== null && (
                  <div className="px-[3%]">
                     {searchValue === "" && (
                        <>
                           {(specials.hourly.length > 0 || specials.daily.length > 0) && (
                              <div className="flex flex-col">
                                 <div className="HourlyDailyCard-Wrapper">
                                    <Carousel
                                       showArrows={false}
                                       showStatus={false}
                                       showIndicators={false}
                                       centerMode
                                       swipeable={isMoreThanOneSpecial()}
                                       selectedItem={specials.hourly ? 0 : 1}
                                       centerSlidePercentage={90}
                                       autoPlay={false}>
                                       <div className="HourlyDailyCard-Container">
                                          {specials?.daily && (
                                             <HourlyDailyCard
                                                title={"Don’t miss out our hourly special"}
                                                cardTypeText={"Hourly Special"}
                                                extraText={"Explore now"}
                                                images={[hourlyCardImage, hourlyCardImage, hourlyCardImage]}
                                                colorScheme={"purple"}
                                                onClickAction={navigateToHourlySpecials}
                                             />
                                          )}
                                       </div>
                                       <div className="HourlyDailyCard-Container">
                                          {specials?.daily.length > 0 && (
                                             <HourlyDailyCard
                                                title={"Grab our today’s special items"}
                                                cardTypeText={"Today’s Special"}
                                                extraText={"Explore now"}
                                                images={[hourlyCardImage, hourlyCardImage, hourlyCardImage]}
                                                colorScheme={"yellow"}
                                                onClickAction={navigateToDailySpecials}
                                             />
                                          )}
                                       </div>
                                    </Carousel>
                                 </div>
                              </div>
                           )}
                        </>
                     )}
                     <ItemsTab
                        hideTab={searchValue != ""}
                        currentTab={currentTab as any}
                        tabs={TABS}
                        data={tabData}
                        onChange={(value) => setCurrentTab(value as keyof TabData)}
                        renderComponent={
                           <ItemTabContent
                              searchQuery={searchValue}
                              currentTab={currentTab}
                              data={tabData[currentTab]}
                           />
                        }
                     />
                     <div className="homepage-powered-by-text my-3 font-custom tracking-normal">
                        Powered by
                        <a
                           className="homepage-footer-text font-custom tracking-wide"
                           href="https://www.thebeautifulmenu.com">
                           Thebeautifulmenu.com
                        </a>
                     </div>
                  </div>
               )}
               {openFilter === true && (
                  <BottomModal height="18rem">
                     <FilterModal handleClose={() => setOpenFilter(false)} />
                  </BottomModal>
               )}
            </div>
         </div>
      </>
   );
};

export default HomePage;
