/** @format */

import axios, { AxiosResponse } from "axios";
import { BACKEND_URL } from "src/definitions";
import { trackPromise } from "react-promise-tracker";

const BASE_URL = BACKEND_URL;

const getHeaders = () => {
   const TOKEN = localStorage.getItem("token");
   return {
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${TOKEN}`,
   };
};

export const GetAPI = (endPoint: any) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .get(`${BASE_URL}${endPoint}`, {
               headers: getHeaders(),
            })
            .then((response: AxiosResponse) => {
               resolve(response);
            })
            .catch((err) => reject(err)),
      );
   });
};

export const PostAPI = (endPoint: any, payload: any) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .post(`${BASE_URL}${endPoint}`, payload, { headers: getHeaders() })
            .then((response: AxiosResponse) => {
               resolve(response);
            })
            .catch((err) => reject(err)),
      );
   });
};

export const PostFormDataAPI = (endPoint: any, payload: any) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .post(`${BASE_URL}${endPoint}`, payload, {
               headers: getHeaders(),
            })
            .then((response: AxiosResponse) => {
               resolve(response);
            })
            .catch((err) => reject(err)),
      );
   });
};

export const PutAPI = (endPoint: any, payload: any) => {
   return new Promise((resolve, reject) => {
      axios
         .put(`${BASE_URL}${endPoint}`, payload, { headers: getHeaders() })
         .then((response: AxiosResponse) => {
            resolve(response);
         })
         .catch((err) => reject(err));
   });
};

export const DeleteAPI = (endPoint: any, payload?: any) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .delete(`${BASE_URL}${endPoint}`, { headers: getHeaders() })
            .then((response: AxiosResponse) => {
               resolve(response);
            })
            .catch((err) => reject(err)),
      );
   });
};
