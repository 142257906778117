/** @format */

import React from "react";
import "./index.scss";
import { useApp } from "src/store/useApp";

export default function OfferMessage() {
   const { merchantDetails, getNearestOffer, isOfferTypeIsPercentage, getCartTotal } = useApp();
   const cartTotal = getCartTotal();
   const { offer_value } = getNearestOffer();
   const { currency } = merchantDetails;

   const discount = isOfferTypeIsPercentage(getNearestOffer().offer_id) ? getNearestOffer()?.discount_val + "%" : currency + getNearestOffer()?.discount_val;

   const amountToAdd = (offer_value - cartTotal).toFixed(2);

   return (
      <>
         {cartTotal > 0 && getNearestOffer() && getNearestOffer()?.offer_value > cartTotal && (
            <div className="offers-message-wrapper">
               <div className="offers-message-container">
                  <img
                     className="offer-message-hurray-icon"
                     src={require("../../asset/images/HurrayIcon.png")}
                  />
                  <div>
                     {/* <div className="offers-message-row">
                  Add items worth {currency}{offerValue} and get{" "}
                  <div className="offer-bold">
                     {discount} off {maximum_allowed_discount > 0 && `upto {currency}${maximum_allowed_discount}`}
                  </div>
               </div> */}
                     <div className="offers-message-row">
                        Add {currency}
                        {amountToAdd} more and get
                        <div className="offer-bold">
                           {discount} off
                           {/* {maximum_allowed_discount > 0 && `upto {currency}${maximum_allowed_discount}`} */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
