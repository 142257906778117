/** @format */

import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "src/store/store";
import { theme } from "src/utils/theme";

const queryClient = new QueryClient();
interface ProviderProps {
   children: React.ReactNode;
}

const Providers: React.FC<ProviderProps> = ({ children }) => {
   const queryClient = new QueryClient();
   return (
      <Provider store={store}>
         <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
               <PersistGate
                  persistor={persistor}
                  loading={null}>
                  {children}
               </PersistGate>
            </ThemeProvider>
         </QueryClientProvider>
      </Provider>
   );
};

export default Providers;
