/** @format */

import { useNavigate, useParams } from "react-router-dom";
import RightArrow from "../../asset/icons/RightArrow";
import LoyaltyCup from "../../asset/vectors/LoyaltyCup";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";
import "./index.scss";
/** @format */

export default function BecomeOurMember() {
   const navigate = useNavigate();
   const { mid, qid } = useParams();
   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);

   const onPressCard = () => {
      if (accessToken !== null) {
         if (qid !== undefined) {
            navigate(`/${mid}/${qid}/loyaltyprogram`);
         } else {
            navigate(`/${mid}/loyaltyprogram`);
         }
      } else {
         localStorage.setItem("redirect", "loyaltyprogram");
         navigate(qid !== undefined ? `/${mid}/${qid}/login` : `/${mid}/login`);
      }
   };
   console.log(qid, "qid");
   return (
      <div
         onClick={onPressCard}
         className="become-our-member-wrapper mb-4">
         <div className="become-our-member-container">
            <h4 className="become-our-member-text">
               Join the <br /> Exclusive Club
            </h4>
            <div className="grab-text">
               Join for free, Earn rewards, <br /> Enjoy more!
            </div>
            <div className="subscribe-now-button">
               JOIN NOW <RightArrow type={1} />
            </div>
            <div className="loyalty-cup-container">
               <LoyaltyCup />
            </div>
         </div>
      </div>
   );
}
