/** @format */

import React, { useState, useEffect } from "react";
import "./style.scss";
import PageHeader from "../../component/PageHeader";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import { useAppSelector } from "../../store/hooks";
import qrSelectors from "../../store/Qr/selectors";
import { useNavigate } from "react-router-dom";
import InfoSection from "../../component/InfoSection";
import RandomSelector from "../../component/RandomSeletor";
import ToggleFoodType from "../../component/ToggleFoodType";
import ButtonWithArrow from "../../component/Button";
import SelectCategory from "../../component/SelectCategory";
import CounterCard from "../../component/CounterCard";
import { useAppDispatch } from "../../app/hooks";
import FoodItemBeforeOrder from "../../component/ItemCard";
import WhiteTickIcon from "../../asset/icons/WhiteTickIcon";
import ShuffleBlackIcon from "../../asset/icons/ShuffleIBlackIcon";
import CountInput from "../../component/CountInput";
import { cartActions } from "../../store/cart/reducer";
import { surpriseMeActions } from "../../store/surprise_me/reducer";
import {
  completelyRandom,
  notSoRandom,
  filterItemsBasedOnType,
} from "../../api/surprise_me";
import Loader from "../../component/Loader";
import { resourceLimits } from "worker_threads";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

type Props = {};

const RANDOM = ["Completely random", "Not so random"];
const TYPE = ["Non-Veg", "veg", "mixed"];

const SurpriseMe = (props: Props) => {
  const filterConf = useAppSelector<any>(
    (state) => state.surpriseMe.filterConf
  );
  const { mid, qid }: any = useParams();
  const navigate = useNavigate();
  const [toggledId, settoggledId] = useState<any>();
  const [expand, setExpand] = useState(false);
  const [loading, setloading] = useState(false);
  const [categoriesBasedItemType, setcategoriesBasedItemType] = useState([]);
  const menuItems = useAppSelector<any>((state) => state.menuItems.menu);
  const [random, setRandom] = useState("Completely random");
  const [selectedCat, setselectedCat] = useState<any>([]);
  const dispatch = useAppDispatch();
  const notSoRandomItems = useAppSelector(
    (state) => state.surpriseMe.notSoRandomItems
  );
  const completelyRandomItems = useAppSelector(
    (state) => state.surpriseMe.completelyRandomItems
  );

  console.log(menuItems);

  const [surprise, setSurprise] = useState<boolean>(true);

  const [filter, setFilter] = useState<any>(
    {
      random: random,
      categories: [],
      item_preference: "",
      amount: 0,
      number_of_items: 0,
    } || filterConf
  );
  console.log(filter);

  console.log(filter);
  const update_category = (item: any) => {
    console.log("update_category", item);

    let temp = [...filter?.categories];
    let existingItems = [...selectedCat];

    if (existingItems.includes(item)) {
      const categories = temp.filter((i: any) => i.category_id != item.id);
      console.log("IT", categories);

      setFilter({ ...filter, categories: categories });
      const index = existingItems.indexOf(item);
      existingItems.splice(index, 1);
      setselectedCat(existingItems);
    } else {
      setFilter({
        ...filter,
        categories: [
          ...filter.categories,
          {
            category_id: item?.id,
            count: 1,
          },
        ],
      });
      setselectedCat([...existingItems, item]);
    }
  };

  const update_random = (item: any) => {
    setRandom(item);
  };
  const update_type = (item: any) => {
    setFilter({ ...filter, item_preference: item });
  };

  const handleSurprise = async () => {
    if (random === "Not so random") {
      const body = {
        categories: filter?.categories,
        item_preference: filter.item_preference,
        merchant_id: mid,
      };
      console.log(body);
      setloading(true);
      const request = await notSoRandom(body);

      if (request.statusCode === 200 || request.statusCode === 201) {
        if (request.payload.items.length === 0) {
          toast.info("No items found, Please try different filter");
        } else {
          let PAYLOADITEMS = request.payload.items?.map((i: any) => {
            return {
              ...i,
              item_source: "from_suprise_me_workflow",
            };
          });
          dispatch(
            surpriseMeActions.setNotSoRandomItems({
              ...request.payload,
              items: PAYLOADITEMS,
            })
          );
          console.log("PAYLoAd", notSoRandomItems);
          setSurprise(false);
        }
      }
      setloading(false);
    } else if (random === "Completely random") {
      const body = {
        categories: menuItems?.map((i: any) => {
          return {
            category_id: i?.id,
            count: filter.number_of_items,
          };
        }),

        item_preference: filter.item_preference,
        merchant_id: mid,
        amount: parseInt(filter.amount),
        number_of_items: filter.number_of_items,
      };
      setloading(true);

      const request = await completelyRandom(body);
      console.log("COMPLETELY RANDOM REQUEST", request);
      console.log("request.payload", request.payload);

      if (request.statusCode === 200 || request.statusCode === 201) {
        if (request.payload.items.length === 0) {
          toast.info("No items found, Please try different filter");
        } else {
          let PAYLOADITEMS = request.payload.items?.map((i: any) => {
            return {
              ...i,
              item_source: "from_suprise_me_workflow",
            };
          });
          dispatch(
            surpriseMeActions.setCompletelyRandomItems({
              ...request.payload,
              items: PAYLOADITEMS,
            })
          );
          console.log("CompletelyRandomItems", completelyRandomItems);
          setSurprise(false);
        }
      }

      setloading(false);
    }
  };

  const handleReset = () => {
    dispatch(surpriseMeActions.resetFilter());
    setSurprise(true);
    setFilter({
      random: random,
      categories: [],
      item_preference: "",
      amount: 0,
      number_of_items: 0,
    });
    setRandom("Completely random");
    dispatch(cartActions.clearCart(completelyRandomItems || notSoRandomItems));
    setselectedCat([]);
  };

  const isValidate =
    random === "Completely random"
      ? filter.number_of_items !== 0 &&
        filter.amount !== 0 &&
        filter.item_preference !== ""
        ? false
        : true
      : filter.item_preference !== "" && selectedCat?.length > 0
      ? false
      : true;

  const handleAddToCart = () => {
    if (random === "Completely random") {
      for (const i in completelyRandomItems) {
        dispatch(cartActions.addFoodItem(completelyRandomItems[i]));
      }
      if (qid !== undefined) {
        navigate(`/${mid}/${qid}/checkout`);
      } else {
        navigate(`/${mid}/checkout`);
      }
      dispatch(surpriseMeActions.setFilterConf(filter));
    } else {
      for (const i in notSoRandomItems) {
        dispatch(cartActions.addFoodItem(notSoRandomItems[i]));
      }
      if (qid !== undefined) {
        navigate(`/${mid}/${qid}/checkout`);
      } else {
        navigate(`/${mid}/checkout`);
      }
      dispatch(surpriseMeActions.setFilterConf(filter));
    }
  };

  const onPressBackArrow = () => {
    console.log("onPressBackArrow");

    if (completelyRandomItems.length > 0 || notSoRandomItems.length > 0) {
      setSurprise(true);
    } else {
      navigate(-1);
    }
  };

  const onChangeItemType = async () => {
    setFilter({ ...filter, number_of_items: 0, categories: [] });
    setselectedCat([]);
    if (filter.item_preference !== "" && filter.item_preference !== "mixed") {
      const data = {
        merchant_id: mid,
        item_type: filter.item_preference.toLowerCase(),
      };
      setloading(true);
      const request = await filterItemsBasedOnType(data);

      if (request.statusCode === 200 || request.statusCode === 201) {
        setcategoriesBasedItemType(request.payload.categories);
        console.log("PAYLoAd", request.payload);
      }
      setloading(false);
      console.log("onChangeItemType", data);
    } else {
      setcategoriesBasedItemType([]);
    }
  };

  useEffect(() => {
    onChangeItemType();
  }, [filter.item_preference]);

  return (
    <div>
      <ToastContainer hideProgressBar={true} position="bottom-center" />
      <Loader Load={loading} />
      <div className="surprise-me-header">
        <div onClick={onPressBackArrow}>
          <BackArrowIcon />
        </div>
        <div className="surprise-me-header-text">Surprise me</div>
        <div className="surprise-me-header-btn" onClick={handleReset}>
          Reset
        </div>
      </div>
      {surprise ? (
        <>
          <InfoSection isClicked={expand} setClick={setExpand} />
          <div className="surprise-me-random-selection-wrapper col-12">
            {RANDOM.map((item, index) => (
              <RandomSelector
                title={item}
                key={index}
                isSelect={random === item ? true : false}
                onClickHandler={() => {
                  update_random(item);
                }}
              />
            ))}
          </div>
          <div className="surprise-me-toggle-wrapper">
            <div className="surprise-me-food-type-header">Type of food</div>
            <div className="surprise-me-random-selection-wrapper">
              {TYPE.map((item, index) => (
                <ToggleFoodType
                  title={item}
                  key={index}
                  isSelect={filter?.item_preference === item ? true : false}
                  onClickHandler={() => {
                    update_type(item);
                  }}
                />
              ))}
            </div>
          </div>
          <div className="surprise-me-list-container">
            <div className="surprise-me-list-header">
              {random === "Completely random" ? (
                "Number of items"
              ) : (
                <>
                  Choose category
                  <span className="surprise-me-counters">
                    Count:{" "}
                    <span className="surprise-me-count">
                      {filter?.categories?.length}
                    </span>
                    / 5
                  </span>
                </>
              )}
            </div>
            <div className="surprise-me-input-field-wrapper">
              {random === "Completely random" ? (
                <CountInput filter={filter} setFilter={setFilter} />
              ) : (
                <SelectCategory
                  selectedCat={selectedCat}
                  handleCategory={update_category}
                  data={menuItems}
                  categoriesBasedItemType={categoriesBasedItemType}
                  selectedCategories={filter.categories}
                />
              )}
            </div>
            {random === "Completely random" && (
              <>
                <div className="surprise-me-list-header">Amount</div>
                <div className="surprise-me-input-field-wrapper">
                  <div className="surprise-me-input-field">
                    <input
                      type="number"
                      className="surprise-me-amount-input"
                      value={filter.amount}
                      onChange={(e) => {
                        setFilter({ ...filter, amount: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {random !== "Completely random" && filter?.categories?.length > 0 && (
            <div className="surprise-me-selected-categories-container">
              <div className="surprise-me-list-header">
                Choose no of items in category
              </div>
              <div className="surprise-me-selected-categories-sub-container">
                <div className="surprise-me-selected-categories-header-wrapper">
                  <div className="surprise-me-selected-categories-header">
                    Categories
                  </div>
                  <div className="surprise-me-selected-categories-header">
                    Count
                  </div>
                </div>
                <div>
                  {selectedCat?.map((item: any) => (
                    <CounterCard
                      setFilter={setFilter}
                      item={item}
                      filter={filter}
                      handleCategory={update_category}
                      isSelected={selectedCat?.includes(item)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="surprise-me-btn-wrapper">
            <ButtonWithArrow
              onClickHandler={handleSurprise}
              buttonText="Surprise me"
              fontColor="#fff"
              background="#E63946"
              disabled={isValidate}
            />
          </div>
        </>
      ) : (
        <div className="surprise-me-cr-main-container">
          <div className="surprise-me-cr-items-container">
            {random === "Completely random"
              ? completelyRandomItems?.map((item: any, index: any) => {
                  return (
                    <>
                      {/* <div className='surprise-me-cr-header-items-wrapper'>
                    <div className='surprise-me-cr-items-header'>
                      <div className='header'>{`${category_name(item?.category_id)}`}</div>
                      <div className='surprise-me-hr'></div>
                    </div>
                  </div> */}
                      {/* <FoodItemBeforeOrder
                                   cardType={"details"}
                                   mid={mid ?? ""}
                                   item={item}
                                   key={index}
                                   toggledId={toggledId}
                                   updateToggle={() => {}}
                                /> */}
                    </>
                  );
                })
              : notSoRandomItems?.map((item: any, index: any) => {
                  return (
                    <>
                      {/* <div className='surprise-me-cr-header-items-wrapper'>
                      <div className='surprise-me-cr-items-header'>
                        <div className='header'>{`${category_name(item?.category_id)}`}</div>
                        <div className='surprise-me-hr'></div>
                      </div>
                    </div> */}
                      {/* <FoodItemBeforeOrder
                                   mid={mid ?? ""}
                                   item={item}
                                   key={index}
                                   toggledId={toggledId}
                                   updateToggle={() => {}}
                                /> */}
                    </>
                  );
                })}
          </div>
          <div className="surprise-me-cr-btn-wrapper">
            <ButtonWithArrow
              onClickHandler={handleSurprise}
              buttonText="Reshuffle"
              fontColor="#000"
              background="#F9CA41"
              CustomIcon={<ShuffleBlackIcon />}
            />
            <ButtonWithArrow
              onClickHandler={handleAddToCart}
              buttonText="Add to cart"
              fontColor="#fff"
              background="#0F6656"
              CustomIcon={<WhiteTickIcon />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SurpriseMe;
