/** @format */

import React, { EventHandler } from "react";
import "./index.scss";
import DicountIcon from "../../asset/icons/DiscountIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useApp } from "src/store/useApp";
import { useParams } from "react-router-dom";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import { MouseEventHandler } from "react";

export default function ApplyCoupon() {
   const { appliedOffer, merchantDetails, getOfferById, getMaximumDiscount, onRemoveOffer } = useApp();
   const { currency } = merchantDetails;
   const appNavigate = useAppNavigate();

   const appliedOfferDetails = getOfferById(appliedOffer);

   const onRemove = (event: any) => {
      event.stopPropagation();
      onRemoveOffer();
   };

   return (
      <div
         className="apply-coupon-container"
         onClick={() => appNavigate({ path: "/offers" })}>
         <div className="apply-coupon-wrapper">
            <DicountIcon />
            {appliedOffer ? (
               <div className="ms-2 ">
                  <div className="fw-bold font-custom">"{appliedOfferDetails?.offer_name?.toUpperCase()}" applied</div>
                  <div
                     className="mt-1 fw-bold font-custom"
                     style={{ fontSize: "0.8rem", color: "#2FBF71" }}>
                     {"You saved " + currency + getMaximumDiscount(appliedOffer) + " on this order!"}
                  </div>
               </div>
            ) : (
               <div className="apply-coupon-text font-semibold font-custom">Apply coupons</div>
            )}
         </div>
         {appliedOffer ? (
            <div
               onClick={onRemove}
               className="font-semibold text-[#D90429] text-[16px] font-custom">
               Remove
            </div>
         ) : (
            <ArrowForwardIosIcon sx={{ color: "#1C1B1F" }} />
         )}
      </div>
   );
}
