/** @format */

import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AddOnItem, Item } from "src/interface/item";
import { useApp } from "src/store/useApp";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";
import AddonsModal from "../AddOnsModal";
import CustomAccordion from "../CustomAccordion";
import PlusAndMinusWithQuantity from "../PlusAndMinusWithQuantity";
import "./index.scss";

interface Props {
   item: Item;
}

export default function CartCard({ item }: Props) {
   const { merchantDetails, addMoreItemFlow, addOrRemoveItem, getCartItem, addOrRemoveAddOn } = useApp();
   const { currency } = merchantDetails;
   const { mid: merchant_id } = useParams();
   const cartItem = getCartItem(item?.item_id);
   const [showAddonsModal, setshowAddonsModal] = useState(false);

   const handleRemoveFromCart = () => {
      addOrRemoveItem(item, "remove");
   };

   const handleAddToCart = () => {
      console.log("ADD TO CART CALLED");
      const cookies = Object.fromEntries(document.cookie.split("; ").map((v) => v.split(/=(.*)/s).map(decodeURIComponent)));
      const isQuantityReached = item?.manage_inventory ? cartItem?.count === item.remaining_quantity : false;
      if (isQuantityReached) {
         toast.error("No more quantity available");
      } else {
         addOrRemoveItem(item, "add");
      }
   };
   const addAddonToItem = (addon: AddOnItem) => {
      const payload = {
         ...addon,
         item_id: addon.item_id,
      };
      addOrRemoveAddOn(payload, "add");
   };

   const removeAddonFromItem = (addon: AddOnItem) => {
      const payload = {
         ...addon,
         item_id: addon.item_id,
      };
      addOrRemoveAddOn(payload, "remove");
   };

   const getCustomizationsCount = () => {
      let total = item?.customizations?.length || 0;
      if (item?.other_customization !== undefined && item?.other_customization !== "") {
         total = total + 1;
      }
      return total;
   };

   return (
      <div className="cart-card-container">
         <Toaster position="bottom-center" />
         <div className="cart-main-item-container">
            <div className="cart-item-name-and-price-container">
               <div className="cart-item-name font-custom">{capitalizeFirstLetter(item?.item_name)}</div>

               {(item?.add_ons?.length > 0 || item?.customizations?.length > 0) && (
                  <div
                     onClick={() => setshowAddonsModal(true)}
                     className="underline text-[#0F6656] font-semibold text-[12px] mt-1 font-custom">
                     Customizable
                  </div>
               )}
               <div className="cart-item-price font-custom">
                  {currency}
                  {item?.price}
               </div>
            </div>
            <AddonsModal
               item={item}
               visible={showAddonsModal}
               onClose={() => setshowAddonsModal(false)}
            />
            <div className="flex items-end flex-col">
               <PlusAndMinusWithQuantity
                  disableRemove={false}
                  onPressRemove={handleRemoveFromCart}
                  quantity={item?.count}
                  onPressAdd={handleAddToCart}
               />
               <div className="cart-item-sub-total-price font-custom">
                  {currency} {((item?.count as number) * item?.price_after_discount).toFixed(2)}
               </div>
            </div>
         </div>
         {item?.add_ons?.length > 0 && (
            <CustomAccordion
               type={"normal"}
               header={"Add on items"}
               details={
                  <>
                     {item.add_ons.map((item, index) => {
                        return (
                           <div className="cart-main-item-container">
                              <div className="cart-item-name-and-price-container">
                                 <div className="cart-item-name font-custom">{capitalizeFirstLetter(item?.item_name)}</div>
                                 <div className="cart-item-price font-custom">
                                    {currency}
                                    {item?.price}
                                 </div>
                              </div>
                              <div>
                                 <PlusAndMinusWithQuantity
                                    disableRemove={false}
                                    onPressRemove={() => removeAddonFromItem(item)}
                                    quantity={item?.count}
                                    onPressAdd={() => addAddonToItem(item)}
                                 />
                                 <div className="cart-item-sub-total-price font-custom">
                                    {currency}
                                    {(item?.count as number) * item?.price}
                                 </div>
                              </div>
                           </div>
                        );
                     })}
                  </>
               }
            />
         )}

         {getCustomizationsCount() > 0 && (
            <CustomAccordion
               type={"normal"}
               header={"Customizations"}
               details={
                  <>
                     {item?.customizations?.length > 0 && (
                        <>
                           {item?.customizations.map((customization) => {
                              return (
                                 <div style={{ marginTop: "0.6rem" }}>
                                    <div className="cart-item-name">
                                       {capitalizeFirstLetter(customization?.customisation_title)}
                                       <span
                                          style={{ fontWeight: "normal" }}
                                          className="cart-item-price">
                                          <div className="mt-1">
                                             {customization?.selected_options?.map((item) => (
                                                <div>{capitalizeFirstLetter(item)}</div>
                                             ))}
                                          </div>
                                       </span>
                                    </div>
                                 </div>
                              );
                           })}
                        </>
                     )}
                     {item?.other_customization !== "" && (
                        <div style={{ marginTop: "0.6rem" }}>
                           {/* <div className="cart-item-name">Other customization </div> */}
                           <span
                              style={{ fontWeight: "normal" }}
                              className="cart-item-price">
                              {item?.other_customization}
                           </span>
                        </div>
                     )}
                  </>
               }
            />
         )}
      </div>
   );
}
