export const FILTER_DATA = [
    {
        id: "veg",
        label: "Veg",
        disableOn: "nonVeg"
    },
    {
        id: "nonVeg",
        label: "Non-Veg",
        disableOn: "veg"

    },
    {
        id: "inStock",
        label: "In-stock"
    }
]