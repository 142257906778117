/** @format */

import React from "react";
import { ReactComponent as BlackForwardIcon } from "../../asset/svg/svg/OrderSummary/BlackForwardIcon.svg";
import { ReactComponent as OrangeForwardIcon } from "../../asset/svg/svg/OrderSummary/OrangeForwardIcon.svg";
import OrderStatusTag from "../../component/OrderSummary/OrderStatusTag";
import showMenuSelector from "../../store/showMenu/selectors";
import { useAppSelector } from "../../store/hooks";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import { useApp } from "src/store/useApp";

export default function OrderHistoryCard({ orderHistory, totalItems }) {
   const { merchantDetails } = useApp();
   const { currency } = merchantDetails;

   const appNavigate = useAppNavigate();

   const onClick = () => {
      appNavigate({
         path: `/order_summary/${orderHistory.order_id}`,
      });
   };

   return (
      <div className="p-2 pb-3">
         <div
            key={orderHistory.order_id}
            className="flex flex-col border-2 rounded-xl">
            <div className="flex justify-between items-center py-2.5 px-2">
               <div>
                  <p className="text-base font-bold font-custom">{`OrderID #${orderHistory.order_id}`}</p>
                  <p className="text-[#868B8C] text-xs font-custom">
                     {totalItems} items, {orderHistory.order_date} - {orderHistory.order_time}
                  </p>
               </div>
               <OrderStatusTag
                  orderStatus={orderHistory}
                  orderHistoryPage={true}
               />
            </div>
            <div className=" font-custom flex items-center justify-between py-3 text-[#0F6656] text-base font-semibold border-b border-t px-2">
               <p>Grand total</p>
               <p>
                  {currency}
                  {orderHistory.amount_after_taxes}
               </p>
            </div>
            <div
               onClick={onClick}
               className="self-center  py-3 text-[#EB5E28] text-base font-semibold">
               {orderHistory?.order_status === "order_completed" ? (
                  <div className="flex items-center mx-auto cursor-pointer gap-2 font-custom">
                     <p className="text-[black] font-custom">See details</p> <BlackForwardIcon />
                  </div>
               ) : (
                  <div className="flex items-center mx-auto font-custom cursor-pointer gap-2">
                     <p>Track your order</p>
                     <OrangeForwardIcon />
                  </div>
               )}
            </div>
         </div>
      </div>
   );
}
