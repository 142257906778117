import React from "react";
import Skeleton from "react-loading-skeleton";

interface MenuPageSkeletonProps {
  isVisible: boolean;
}

const MenuPageSkeleton: React.FC<MenuPageSkeletonProps> = ({ isVisible }) => {
  return (
    <div className="w-full h-full fixed z-10 bg-white" hidden={!isVisible}>
      <Skeleton
        height={"2.5rem"}
        width={"80%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 35,
          marginTop: 20,
        }}
      />
      <Skeleton
        height={"3rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Skeleton
          height={"2rem"}
          width={"5rem"}
          style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
          }}
        />
        <Skeleton
          height={"2rem"}
          width={"5rem"}
          style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
          }}
        />
        <Skeleton
          height={"2rem"}
          width={"5rem"}
          style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
          }}
        />{" "}
      </div>
      <Skeleton
        height={"5rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"5rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />{" "}
      <Skeleton
        height={"5rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />{" "}
      <Skeleton
        height={"5rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />{" "}
      <Skeleton
        height={"5rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />{" "}
    </div>
  );
};

export default MenuPageSkeleton;
