/** @format */

import { Modal } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { useApp } from "src/store/useApp";
import RoundCloseIcon from "../../asset/icons/RoundCloseIcon";
import Button from "../../component/Button";
import PlusAndMinusWithQuantity from "../../component/PlusAndMinusWithQuantity";
import { useAppSelector } from "../../store/hooks";
import showMenuSelector from "../../store/showMenu/selectors";
import "./index.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
   return (
      <MuiAlert
         elevation={6}
         //@ts-ignore
         ref={ref}
         variant="filled"
         {...props}
      />
   );
});

const MAX_COINS_CAN_APPLY = 10;

interface Props {
   visible: boolean;
   onClose: () => void;
}

export default function ApplyLoyaltyCoinsModal({ visible, onClose }: Props) {
   const { availableCoins: AVAILABLE_COINS, loyaltyDetails: LoyaltyDetails, value_for_1000_coins, merchantDetails, getCartTotal, onApplyLoyaltyCoins } = useApp();

   const total = getCartTotal();
   const [coinsCount, setcoinsCount] = useState(0);
   const [isCoinsSufficient, setisCoinsSufficient] = useState(true);
   const [discountAmount, setdiscountAmount] = useState(value_for_1000_coins);
   const [availableCoins, setavailableCoins] = useState(AVAILABLE_COINS);
   const [insufficientLoyaltyCoins, setinsufficientLoyaltyCoins] = useState(availableCoins < 1000);
   const [showSnackbar, setshowSnackbar] = useState(false);

   const { currency } = merchantDetails;

   const checkCoinsSufficient = () => {
      if (1000 * coinsCount > AVAILABLE_COINS) {
         setisCoinsSufficient(false);
      } else {
         setisCoinsSufficient(true);
      }
   };

   useEffect(() => {
      checkCoinsSufficient();
      setdiscountAmount(LoyaltyDetails?.value_for_1000_coins * coinsCount);
      updateAvailableCoins();
   }, [coinsCount]);

   const updateAvailableCoins = () => {
      setavailableCoins(AVAILABLE_COINS - 1000 * coinsCount);
   };

   const getMaximumPointsCanBeAppliedInPrice = () => {
      return Math.floor(total / LoyaltyDetails?.value_for_1000_coins);
   };

   const hadnleApplyAllCoins = () => {
      if (LoyaltyDetails?.value_for_1000_coins > total) {
         setshowSnackbar(true);
      } else if (discountAmount < total && availableCoins >= 1000) {
         let totalCoinsCount = getMaximumPointsCanBeAppliedInPrice();
         if (totalCoinsCount > MAX_COINS_CAN_APPLY) {
            totalCoinsCount = MAX_COINS_CAN_APPLY;
         }
         const totalCoinsAmount = totalCoinsCount * 10;

         console.log(totalCoinsCount, "totalCoinsCount after syv", total, totalCoinsCount * 10);
         if (total >= totalCoinsAmount) {
            setcoinsCount(totalCoinsCount);
         }
      } else {
         setshowSnackbar(true);
      }
   };

   const handleClose = (event: any, reason: any) => {
      if (reason === "clickaway") {
         return;
      }
      setshowSnackbar(false);
   };

   const handleApplyDiscount = () => {
      onApplyLoyaltyCoins(coinsCount);
      onClose();
   };

   const onPressAdd = () => {
      if (LoyaltyDetails?.value_for_1000_coins >= total) {
         setshowSnackbar(true);
      } else if (discountAmount < total && availableCoins >= 1000) {
         if (coinsCount < getMaximumPointsCanBeAppliedInPrice() && coinsCount < MAX_COINS_CAN_APPLY) {
            setcoinsCount(coinsCount + 1);
         } else {
            setshowSnackbar(true);
         }
      } else {
         setshowSnackbar(true);
      }
   };

   return (
      <Modal open={visible}>
         <div className="apply-loyalty-modal-container">
            <div
               className="round-close-icon"
               onClick={onClose}>
               <RoundCloseIcon />
            </div>
            <Snackbar
               open={showSnackbar}
               autoHideDuration={8000}
               onClose={handleClose}>
               {/* @ts-ignore */}
               <Alert
                  onClose={() => setshowSnackbar(false)}
                  severity="warning"
                  sx={{ width: "100%" }}>
                  {insufficientLoyaltyCoins
                     ? "To apply a discount, you'll need at least 1000 loyalty coins; don't worry, upon confirming your order, additional loyalty coins will be added to your wallet."
                     : LoyaltyDetails?.value_for_1000_coins > total
                     ? "Discount cannot be greater than the total amount"
                     : "You have reached the maximum deduction limit based on the order value."}
               </Alert>
            </Snackbar>
            <div className="apply-loyalty-modal-title-container">
               <div className="apply-discount-text">Apply discount</div>
               <div className="available-coins-text">
                  Available coins :<div className="available-coins"> {availableCoins < 0 ? 0 : availableCoins}</div>
               </div>
            </div>
            <div className="divider"></div>
            <div className="coins-and-qty-container">
               <div className="thousand-coins">1000</div>
               <div className="x">X</div>
               <div className="coins-qty-container">
                  <PlusAndMinusWithQuantity
                     disableRemove={false}
                     quantity={coinsCount}
                     onPressAdd={onPressAdd}
                     onPressRemove={() => coinsCount > 0 && setcoinsCount(coinsCount - 1)}
                  />
               </div>
            </div>
            {!isCoinsSufficient && <div className="insufficient-coins-text">Insufficient coins</div>}
            <div className="or-text">OR</div>
            <div
               className="use-all-coins"
               style={{ opacity: isCoinsSufficient ? 1 : 0.5 }}
               onClick={() => isCoinsSufficient && hadnleApplyAllCoins()}>
               Use all coins
            </div>
            <div className="discount-details-container">
               <div className="discount-detail-row">
                  <div className="discount-detail-key">Total amount to be paid</div>
                  <div className="discount-detail-value">
                     {currency}
                     {total}
                  </div>
               </div>
               <div className="discount-detail-row">
                  <div className="discount-detail-key">
                     Discount<div className="using-coins-text"> (Using {1000 * coinsCount} coins)</div>
                  </div>
                  <div
                     style={{ color: "#2FBF71" }}
                     className="discount-detail-value">
                     -{currency}
                     {discountAmount}
                  </div>
               </div>
               <div className="divider"></div>
               <div className="discount-detail-row">
                  <div
                     style={{ color: " #4361EE" }}
                     className="discount-detail-key">
                     Final amount
                  </div>
                  <div className="discount-detail-value">
                     {currency}
                     {total - discountAmount}
                  </div>
               </div>
            </div>
            <div style={{ marginTop: "5rem", width: "100%" }}>
               <Button
                  onClickHandler={handleApplyDiscount}
                  buttonText="Apply discount"
                  background="#4361EE"
                  fontColor="#FFFFFF"
                  disabled={!isCoinsSufficient || AVAILABLE_COINS < 1000}
               />
            </div>
         </div>
      </Modal>
   );
}
