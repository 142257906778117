import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

interface PrivateRouteProps {
  children: React.ReactElement;
}

export default PrivateRoute;
