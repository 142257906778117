/** @format */

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EndPoints } from "src/api/endPoints";
import { GetAPI } from "src/api/request";
import OrderStatusPageSkeleton from "src/component/Skeletons/OrderStatusPageSkeleton";
import { useApp } from "src/store/useApp";
import { ReactComponent as OfferArrowDown } from "../../asset/svg/OrderSummary/OfferArrowDown.svg";
import { ReactComponent as OfferArrowUp } from "../../asset/svg/OrderSummary/OfferArrowUp.svg";
import OrderDividerTitle from "../../component/OrderSummary/OrderDividerTitle";
import OrderItemsRow from "../../component/OrderSummary/OrderItemsRow";
import OrderPageHeader from "../../component/OrderSummary/OrderPageHeader";
import OrderStatusIndicator from "../../component/OrderSummary/OrderStatusIndicator";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";
import { SubOrder } from "src/interface/order";
import { Item } from "src/interface/item";

export default function OrderDetails() {
   const [feedBackModal, setFeedBackModal] = useState();
   const [feedBacksubmitModal, setFeedBacksubmitModal] = useState(false);
   const [open, setOpen] = useState(false);
   const { merchantDetails } = useApp();
   const { currency } = merchantDetails;
   const navigate = useNavigate();
   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
   const { orderId, suborderId } = useParams();

   const fetchOrder = () => {
      return GetAPI(EndPoints.orderEndPoints.Order(orderId as string)).then((res: any) => {
         return res?.data;
      });
   };

   const { data: orderDetails, isLoading } = useQuery({
      queryKey: ["orders", orderId],
      queryFn: fetchOrder,
   });

   const subOrderDetails = orderDetails && orderDetails?.items_ordered ? orderDetails?.items_ordered?.find((item: SubOrder) => item.suborder_id === suborderId) : null;

   const OfferHandler = () => {
      setOpen(!open);
   };

   const formatDate = (dateString: string) => {
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.toLocaleDateString("en-US", {
         month: "short",
         day: "numeric",
         year: "numeric",
      });
      const formattedTime = dateObject.toLocaleTimeString("en-US", {
         hour: "numeric",
         minute: "numeric",
         hour12: true,
      });
      const finalFormattedDateTime = `${formattedDate} - ${formattedTime}`;
      return finalFormattedDateTime;
   };

   const handleBackArrow = () => {
      navigate(-1);
   };
   // const handleFeedBackModal = () => {
   //   if ((orderDetails.order_status = "order_completed")) {
   //     setFeedBackModal(true);
   //   }
   // };

   return (
      <>
         <OrderStatusPageSkeleton isVisible={isLoading} />
         <div hidden={isLoading}>
            <OrderPageHeader
               title={`Suborder ID: #${suborderId}`}
               date={`${subOrderDetails?.suborder_items_ordered?.length} items, ${formatDate(subOrderDetails?.created_on)}`}
               HandlerBackArrow={handleBackArrow}
               addItemsButton={false}
               orderId={orderDetails.order_id}
            />
            <OrderStatusIndicator
               completeStatus={orderDetails}
               order_status={subOrderDetails.suborder_status}
               indicator={true}
            />
            <OrderDividerTitle title={"Order details"} />
            <div className="px-2 border-dashed border-b-2">
               <div className="py-2">
                  {subOrderDetails?.suborder_items_ordered?.map((order: Item, orderIndex: number) => (
                     <>
                        <OrderItemsRow
                           key={orderIndex}
                           subdetails={order}
                           itemDetails={order}
                        />
                     </>
                  ))}
               </div>
            </div>
            <div className="px-2 ">
               <div className="flex justify-between items-center mt-2 mb-1 font-custom">
                  <span className="text-sm">Total items</span>
                  <span className="text-sm font-custom">{subOrderDetails?.suborder_items_ordered?.length}</span>
               </div>
               <div className="flex justify-between items-center mb-1">
                  <span className="text-sm font-custom">Sub total</span>
                  <span className="text-sm font-custom">
                     {currency}
                     {subOrderDetails?.total_suborder_amount}
                  </span>
               </div>
               {subOrderDetails?.offers_discount_amount ? (
                  <div
                     className="flex justify-between items-center mb-1"
                     onClick={OfferHandler}>
                     <span className="text-sm flex items-center gap-1 font-custom">Offers {open ? <OfferArrowUp /> : <OfferArrowDown />}</span>
                     <span className="text-sm text-[#2FBF71] font-custom">
                        -{currency}
                        {subOrderDetails?.offers_discount_amount}
                     </span>
                  </div>
               ) : (
                  ""
               )}
               {open && (
                  <div className="pl-3">
                     {subOrderDetails?.offers_discount_amount ? (
                        <div className="flex justify-between items-center mt-2 mb-1">
                           <span className="text-sm font-custom">Offer discount</span>
                           <span className="text-sm font-custom">
                              -{currency}
                              {subOrderDetails?.offers_discount_amount}
                           </span>
                        </div>
                     ) : (
                        ""
                     )}
                     {subOrderDetails?.loyalty_discount_amount ? (
                        <div className="flex justify-between items-center mt-2 mb-1">
                           <span className="text-sm font-custom">Loyalty discount</span>
                           <span className="text-sm font-custom">-${subOrderDetails?.loyalty_discount_amount}</span>
                        </div>
                     ) : (
                        ""
                     )}
                  </div>
               )}
               <div className="flex justify-between items-center py-3 border-b border-t">
                  <span className="text-base font-semibold font-custom">Total price</span>
                  <span className="text-base font-semibold font-custom">
                     {currency}
                     {subOrderDetails?.total_suborder_amount_offers_applied}
                  </span>
               </div>
            </div>

            {/* <FeedBackModal
        open={handleFeedBackModal}
        onClose={() => setFeedBackModal(false)}
        FeedBackSubmitModalClick={handleFeedBackSubmitModal}
      />
      <FeedBackSubmitModal
        open={feedBacksubmitModal}
        onClose={() => setFeedBacksubmitModal(false)}
      /> */}
         </div>
      </>
   );
}
