/** @format */

import { createSlice } from '@reduxjs/toolkit';

export interface OrderState {
  order: any;
  order_id: string;
  isOrderSummaryVisible: boolean;
  loyaltyDetails: any;
  loyaltyPoints: number;
  offers: Array<any>;
}

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    order: {},
    order_id: '',
    isOrderSummaryVisible: true,
    loyaltyDetails: {},
    loyaltyPoints: 0,
    appliedCoupon: '',
    loyaltyUsage: {
      loyaltyCoinsUsed: 0,
      loyaltyDiscount: 0,
    },
    offers: [],
  },
  reducers: {
    setOrder: (state: any, action: any) => {
      state.order = action.payload;
      state.order_id = action.payload.order_id;
    },
    setOrderId: (state: any, action: any) => {
      state.order.order_id = action.payload;
    },
    clearOrder: (state: any, action: any) => {
      state.order = {};
      state.order_id = '';
    },
    setIsOrderSummaryVisible: (state: any, action: any) => {
      state.isOrderSummaryVisible = true;
    },
    setLoyaltyPoints: (state: any, action: any) => {
      state.loyaltyPoints = action.payload;
    },
    setLoyaltyDetails: (state: any, action: any) => {
      state.loyaltyDetails = action.payload;
    },
    setOffers: (state: any, action: any) => {
      console.log('SET OFFERS', action.payload);
      state.offers = action.payload;
    },
    resetOffer: (state: any, action?: any) => {
      state.offers = [];
    },
    setAppliedCoupon: (state: any, action: any) => {
      state.appliedCoupon = action.payload;
    },
    setLoyaltyUsage: (state: any, action: any) => {
      state.loyaltyUsage = {
        loyaltyCoinsUsed: action.payload.loyaltyCoinsUsed,
        loyaltyDiscount: action.payload.loyaltyDiscount,
      };
    },
  },
});

export const orderActions = orderSlice.actions;
