/** @format */

import { ArrowDropDown } from "@mui/icons-material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "src/store/useApp";
import ButtonWithArrow from "../../../component/Button";
import SwipeButton from "../../../component/SwipeButton";
import Sucess from "canvas-confetti";
import "./index.css";
import { useIsAuthenticated } from "src/hooks/useIsAuthenticated";
import { getTotalDiscountedAmount } from "src/store/helper";
import OrderPlaced from "src/component/OrderPlaced";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import { PostAPI } from "src/api/request";
import { EndPoints } from "src/api/endPoints";

interface BillInfoProps {
   title: string;
   value: string;
   show: boolean;
}

const TotalAmountDetails = () => {
   const { merchantDetails, getOrderPayload, getCart, getCartTotal, generateSubOrder, resetCart } = useApp();

   const cart = getCart();
   const cartTotal = getCartTotal();
   const subOrder = generateSubOrder();
   const totalDiscountedAmount = getTotalDiscountedAmount(subOrder);

   const { qid } = useParams();
   const { currency, include_gst } = merchantDetails;
   const [showOffers, setShowOffers] = useState(true);
   const { cgst_percentage, sgst_percentage } = getOrderPayload(qid as string);

   const totalSubOrderAmount = (cartTotal - totalDiscountedAmount).toFixed(2);

   const RenderBillInfo = ({ title, value }: { title: string; value: string }) => {
      return (
         <div className="order-details-container-text">
            <div className="order-details-text font-custom">{title}</div>
            <div className="order-details-content font-custom">{`${value as string}`}</div>
         </div>
      );
   };

   const BillInfo: BillInfoProps[] = [
      {
         title: "Total items",
         value: cart.length.toString(),
         show: true,
      },
      {
         title: "Total amount",
         value: cartTotal.toString(),
         show: true,
      },
      {
         title: "CGST",
         value: `${cgst_percentage}%`,
         show: include_gst,
      },
      {
         title: "SGST",
         value: `${sgst_percentage}%`,
         show: include_gst,
      },
   ];

   return (
      <>
         <div className="flex items-center justify-between py-2  w-[100%]">
            <div className="text-[18px] font-bold font-custom">Bill details</div>
         </div>

         <div className="confirmModalContainer">
            {BillInfo.map((item) => {
               if (item.show) {
                  return (
                     <RenderBillInfo
                        title={item.title}
                        value={item.value}
                     />
                  );
               }
            })}

            <div className="order-details-container-text">
               <div
                  onClick={() => setShowOffers(!showOffers)}
                  className="order-details-text font-custom">
                  Offers
                  <span>
                     <ArrowDropDown sx={{ color: "#000" }} />
                  </span>
               </div>
               <div className="flex items-center">
                  <div
                     style={{ color: "#2FBF71" }}
                     className="order-details-content">
                     -{currency}
                     {totalDiscountedAmount}
                  </div>
               </div>
            </div>
            {showOffers && (
               <div className="Offers-dropdown">
                  <div className="order-details-container-text">
                     <div className="order-details-text font-custom">Offers</div>
                     <div className="flex items-center">
                        <div className="order-details-content font-custom">{`-${currency}${subOrder.offers_discount_amount}`}</div>
                     </div>
                  </div>
                  <div className="order-details-container-text">
                     <div className="order-details-text font-custom">Discount from loyalty coins</div>
                     <div className="flex items-center">
                        <div className="order-details-content font-custom">{`-${currency}${subOrder.loyalty_discount_amount}`}</div>
                     </div>
                  </div>
               </div>
            )}

            <div className="order-details-dotted-divider"></div>
            <div className="order-details-container-text">
               <div className="order-details-text-grand-total font-custom">Total price</div>
               <div className="order-details-content-grand-total text-[#0F191A]">{`${currency}${totalSubOrderAmount}`}</div>
            </div>
         </div>
      </>
   );
};

export default TotalAmountDetails;

{
   /* <SwipeButton
isLoading={false}
text="Swipe to confirm"
onSuccess={handleConfirmOrder}
/> */
}
