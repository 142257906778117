import React from "react";
import Skeleton from "react-loading-skeleton";

interface HomePageSkeletonProps {
  isVisible: boolean;
}

const HomePageSkeleton: React.FC<HomePageSkeletonProps> = ({ isVisible }) => {
  return (
    <div className="w-full h-full fixed bg-white z-10" hidden={!isVisible}>
      <Skeleton
        height={"2.5rem"}
        width={"80%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 35,
          marginTop: 20,
        }}
      />
      <Skeleton
        height={"3rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"5rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"2rem"}
        width={"30%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"3rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"3rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"3rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
      <Skeleton
        height={"3rem"}
        width={"90%"}
        style={{
          alignSelf: "center",
          display: "flex",
          marginLeft: 20,
          marginTop: 10,
        }}
      />
    </div>
  );
};

export default HomePageSkeleton;
