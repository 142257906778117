/** @format */

import { LoyaltyDetails } from "src/interface/loyalty";
import { useAppProps, useLoyaltyProps } from "./storeTypes";
import { GetAPI } from "src/api/request";
import { EndPoints } from "src/api/endPoints";

type SetState = (partial: Partial<useAppProps> | ((state: useAppProps) => Partial<useAppProps>), replace?: boolean) => void;

export const useLoyalty = (set: SetState, get: () => useAppProps): useLoyaltyProps => ({
   loyaltyDetails: {} as LoyaltyDetails,
   availableCoins: 0,
   value_for_1000_coins: 0,
   loyalty_discount_amount: 0,
   loyalty_points_used: 0,
   subscribedToLoyalty: false,
   setLoyaltyAvailableCoins: () => {
      const { merchant_id } = get().merchantDetails;
      GetAPI(EndPoints.loyaltyEndPoints.LoyaltyPoints(merchant_id))
         .then((res: any) => {
            set({ availableCoins: res?.data?.loyalty_points, subscribedToLoyalty: true });
         })
         .catch(() => {});
   },
   setLoyaltyDetails: () => {
      const { merchant_id } = get().merchantDetails;
      GetAPI(EndPoints.loyaltyEndPoints.LoyaltyDetails(merchant_id))
         .then((res: any) => {
            set({ loyaltyDetails: res?.data, value_for_1000_coins: res.data?.value_for_1000_coins });
         })
         .catch(() => {});
   },
   onApplyLoyaltyCoins: (coins: number) => {
      const { value_for_1000_coins } = get();
      const loyalty_discount_amount = value_for_1000_coins * coins;
      const loyalty_points_used = 1000 * coins;
      set({
         loyalty_discount_amount,
         loyalty_points_used,
      });
   },
   resetLoyalty: () => {
      set({
         loyaltyDetails: {} as LoyaltyDetails,
         availableCoins: 0,
         value_for_1000_coins: 0,
         loyalty_discount_amount: 0,
         loyalty_points_used: 0,
      });
   },
});
