import React from "react";
import Skeleton from "react-loading-skeleton";

interface OrderStatusPageSkeletonProps {
  isVisible: boolean;
}

function OrderStatusPageSkeleton({ isVisible }: OrderStatusPageSkeletonProps) {
  return (
    <div hidden={!isVisible} className="">
      <Skeleton height={"17rem"} style={{ marginTop: "1rem" }} />
      <Skeleton height={"7rem"} style={{ marginTop: "1rem" }} />
      <Skeleton height={"7rem"} style={{ marginTop: "1rem" }} />
      <Skeleton height={"7rem"} style={{ marginTop: "1rem" }} />
      <Skeleton height={"7rem"} style={{ marginTop: "1rem" }} />
    </div>
  );
}

export default OrderStatusPageSkeleton;
