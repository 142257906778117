/** @format */

import { RouteObject } from "react-router-dom";
import AlsoOrdered from "../component/AlsoOrdered";
import DiningInConfirmation from "../component/DiningInConfirmation";
import Amenities from "../component/Hotel/Amenities";
import CartScreen from "../component/Hotel/CartScreen";
import Confirmed from "../component/Hotel/Confirmation/Confirmed";
import EmptyCart from "../component/Hotel/EmptyCart/EmptyCart";
import PrivateRoute from "../component/PrivateRoute";
import AboutUs from "../pages/AboutUs";
import Address from "../pages/Address";
import CheckoutPage from "../pages/CheckoutPage";
import ChooseAddressPage from "../pages/ChooseAddressPage";
import ConfirmationPage from "../pages/ConfirmationPage";
import ContactUs from "../pages/ContactUs";
import ExclusiveClub from "../pages/ExclusiveClub/ExclusiveClub";
import LoginPage from "../pages/LoginPage";
import LoyaltyInfo from "../pages/LoyaltyInfo/LoyaltyInfo";
import LoyaltyProgram from "../pages/LoyaltyProgram";
import MenuConversion from "../pages/MenuConversion";
import MenuPage from "../pages/MenuPage";
import OTPVerification from "../pages/OTPVerification";
import OrderDetailsPage from "../pages/OrderDetailsPage";
import OrderDetails from "../pages/OrderDetailsPage/OrderDetails";
import OrderSummary from "../pages/OrderSummary";
import AllOrders from "../pages/AllOrders";
import Pay from "../pages/Pay";
import PreHomePage from "../pages/PreHomePage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy";
import SingleOrderDetailPage from "../pages/SingleOrderDetailPage";
import SurpriseMe from "../pages/SurpriseMe";
import TermsAndConditions from "../pages/TermsAndConditions";
import Razorpay from "../pages/razorpay";
import HomePage from "../pages/HomePage";
import Offers from "src/pages/Offers";

const BASE_URL = "/:mid";
const BASE_URL_WITH_QR_ID = "/:mid/:qid";

export const PROTECTED_ROUTES: RouteObject[] = [BASE_URL, BASE_URL_WITH_QR_ID].map((path) => ({
   path: path,
   children: [
      {
         path: "hotel/cartscreen",
         element: (
            <PrivateRoute>
               <CartScreen />
            </PrivateRoute>
         ),
      },
      {
         path: "hotel/amenities",
         element: (
            <PrivateRoute>
               <Amenities />
            </PrivateRoute>
         ),
      },
      {
         path: "hotel/confirmed",
         element: (
            <PrivateRoute>
               <Confirmed />
            </PrivateRoute>
         ),
      },
      {
         path: "hotel/empty",
         element: (
            <PrivateRoute>
               <EmptyCart />
            </PrivateRoute>
         ),
      },
      {
         path: "orderdetails/:orderId/:suborderId",
         element: (
            <PrivateRoute>
               <OrderDetails />
            </PrivateRoute>
         ),
      },
      {
         path: "payment/:mid",
         element: (
            <PrivateRoute>
               <Pay />
            </PrivateRoute>
         ),
      },
      {
         path: "allorders",
         element: (
            <PrivateRoute>
               <AllOrders />
            </PrivateRoute>
         ),
      },
      {
         path: "order_summary/:orderId",
         element: (
            <PrivateRoute>
               <SingleOrderDetailPage />
            </PrivateRoute>
         ),
      },
      {
         path: "choose-address",
         element: (
            <PrivateRoute>
               <ChooseAddressPage />
            </PrivateRoute>
         ),
      },
      {
         path: "razorpay",
         element: (
            <PrivateRoute>
               <Razorpay />
            </PrivateRoute>
         ),
      },
   ],
}));

export const NON_PROTECTED_ROUTES: RouteObject[] = [
   ...[BASE_URL, BASE_URL_WITH_QR_ID].map((path) => ({
      path: path,
      children: [
         { path: "", element: <HomePage /> },
         { path: "login", element: <LoginPage /> },
         { path: "otp", element: <OTPVerification /> },
         { path: "menu/:item", element: <MenuPage /> },
         { path: "surprise-me", element: <SurpriseMe /> },
         { path: "confirmation", element: <ConfirmationPage /> },
         { path: "checkout", element: <CheckoutPage /> },
         { path: "address", element: <Address /> },
         { path: "offers", element: <Offers /> },
         { path: "ordersummary", element: <OrderSummary /> },
         { path: "dininginconfirmation", element: <DiningInConfirmation /> },
         { path: "loyaltyprogram", element: <LoyaltyProgram /> },
         { path: "joinexclusiveclub", element: <ExclusiveClub /> },
         { path: "loyaltyinfo", element: <LoyaltyInfo /> },
      ],
   })),
   {
      path: "/",
      children: [
         { path: "/", element: <PreHomePage /> },
         { path: "/login", element: <LoginPage /> },
         { path: "/terms-and-conditions", element: <TermsAndConditions /> },
         { path: "/privacy-policy", element: <PrivacyPolicy /> },
         { path: "/refund-policy", element: <RefundPolicy /> },
         { path: "/alsoordered", element: <AlsoOrdered /> },
         { path: "/contact-us", element: <ContactUs /> },
         { path: "/menu-conversion", element: <MenuConversion /> },
         { path: "/about-us", element: <AboutUs /> },
      ],
   },
];
