import React from "react";
import FilledTag from "../FilledTag";
import NoItems from "../NoItems";
import MenuCards from "../ItemTabContent";
import { TabData } from "src/pages/HomePage";

interface ItemsTabProps {
  tabs: Array<any>;
  currentTab: keyof TabData;
  onChange: (value: string) => void;
  data: TabData;
  renderComponent: React.ReactNode;
  hideTab?: boolean;
}

const ItemsTab: React.FC<ItemsTabProps> = ({
  currentTab,
  tabs,
  onChange,
  data,
  renderComponent,
  hideTab,
}) => {
  return (
    <div className="w-full h-full">
      <div hidden={hideTab} className="toggle-chips-row">
        {tabs.map((tab, index) => {
          if (data[tab.value as keyof TabData]?.length > 0) {
            return (
              <div
                key={index}
                className="recommended-chip"
                onClick={() => onChange(tab.value)}
              >
                <FilledTag
                  underline={currentTab === tab.value}
                  text={tab.title}
                  background={currentTab === tab.value ? "#0F6656" : "#FBF6F8"}
                  fontColor={currentTab === tab.value ? "#0F191A" : "#0F191A80"}
                />
              </div>
            );
          }
        })}
      </div>
      {data[currentTab].length > 0 ? renderComponent : <NoItems />}
    </div>
  );
};

export default ItemsTab;
