/** @format */

import { useState, useEffect } from "react";

import { Modal } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { OrderType } from "src/interface/order";
import { useApp } from "src/store/useApp";
import DeliveryIcon from "../../asset/icons/DeliveryIcon";
import DiningInIcon from "../../asset/icons/DiningInIcon";
import TakeAwayIcon from "../../asset/icons/TakeAwayIcon";
import Tick from "../../asset/icons/Tick";
import { classNames } from "../../utils/classNames";
import "./style.scss";

interface OrderTypeProp {
   name: OrderType;
   icon: any;
   enabled: boolean;
}

interface OrderConfirmationModalProps {
   isOpen: any;
   onConfirm: () => void;
   onClose: () => void;
}

const OrderConfirmationModal = ({ isOpen, onConfirm, onClose }: OrderConfirmationModalProps) => {
   const { qid }: any = useParams();
   const [selected, setselected] = useState<OrderType>("Dine-in");

   const { merchantDetails, setOrderType } = useApp();
   const { dine_in, take_away, delivery } = merchantDetails;
   const [isOrderTypeChoosed, setisOrderTypeChoosed] = useState(false);
   const [orderTypes, setorderTypes] = useState<OrderTypeProp[]>([
      {
         name: "Dine-in",
         icon: <DiningInIcon />,
         enabled: dine_in,
      },
      {
         name: "Delivery",
         icon: <DeliveryIcon />,
         enabled: delivery,
      },
      {
         name: "Take away",
         icon: <TakeAwayIcon />,
         enabled: take_away,
      },
   ]);

   const onClickItem = (orderType: OrderTypeProp) => {
      if (orderType.enabled) {
         setselected(orderType.name);
         setOrderType(orderType.name);
      } else {
         toast.error(`This eatery not accepting ${orderType.name.toLowerCase()} now`);
      }
   };

   const checkEnabledOrderTypes = () => {
      const allOrderTypes: { [key: string]: boolean } = {
         dineIn: dine_in,
         delivery: delivery,
         takeAway: take_away,
      };
      const standAloneOrderTypes: { [key: string]: boolean } = {
         delivery: delivery,
         takeAway: take_away,
      };
      let totalEnabledOrders: string[] = [];
      console.log("ENABLED ORDER", totalEnabledOrders);

      if (qid !== undefined && qid !== "") {
         Object.keys(allOrderTypes).forEach((key) => allOrderTypes[key] === true && totalEnabledOrders.push(key));
      } else {
         Object.keys(standAloneOrderTypes).forEach((key) => {
            if (standAloneOrderTypes[key] === true) {
               totalEnabledOrders.push(key);
            }
         });
      }
      console.log("totalEnabledOrders", totalEnabledOrders);
      if (totalEnabledOrders.length > 1) {
         setisOrderTypeChoosed(false);
      } else {
         setisOrderTypeChoosed(true);
         setorderTypes([]);
         switch (totalEnabledOrders[0]) {
            case "dineIn":
               setOrderType("Dine-in");
               break;
            case "delivery":
               setOrderType("Delivery");
               break;
            case "takeAway":
               setOrderType("Take away");
               break;
            default:
               break;
         }
      }
   };

   useEffect(() => {
      checkEnabledOrderTypes();
   }, []);

   return (
      <Modal open={isOpen}>
         <div className="order-confirmation-modal-container">
            <Toaster position="bottom-center" />
            <div className={classNames(isOrderTypeChoosed ? "text-[1.3rem]" : "", "order-confirmation-modal-header pt-4 w-[100%] flex items-center justify-center")}>
               <div className="w-[80%]">{isOrderTypeChoosed ? "Are you sure you want to confirm this order ? " : "Order collection"}</div>
            </div>
            {!isOrderTypeChoosed && <div className="order-confirmation-modal-text">Choose how you would like to collect your order ?</div>}
            <>
               {orderTypes.map((item, index) => {
                  const { name, icon } = item;
                  if ((qid === undefined && item.name === "Dine-in") || !item.enabled) {
                     return null;
                  }
                  return (
                     <div
                        style={{ opacity: item.enabled ? 1 : 0.5 }}
                        className="order-confirmation-modal-btn-container"
                        key={index}>
                        <div
                           style={{
                              border: selected === name ? "2px solid #0F6656" : "1px solid #000000",
                           }}
                           className="order-confirm-option"
                           onClick={() => onClickItem(item)}>
                           {icon}
                           <div className="order-option-text">{name}</div>
                           {selected === name ? <Tick /> : <div></div>}{" "}
                        </div>
                     </div>
                  );
               })}
            </>
            <div
               onClick={() => {
                  !setisOrderTypeChoosed && setOrderType(selected);
                  onConfirm();
               }}
               className="order-confirm-modal-button">
               Confirm
            </div>
            <div
               onClick={onClose}
               className="close-button">
               Cancel
            </div>
         </div>
      </Modal>
   );
};

export default OrderConfirmationModal;
