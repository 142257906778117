/** @format */

import { Add, Remove } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, Radio } from "@mui/material";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { AddOnItem, Customization, Item } from "src/interface/item";
import { useApp } from "src/store/useApp";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";
import ItemCard from "../ItemCard";
import ViewCartButton from "../ViewCartButton";
import { useLocation } from "react-router-dom";

interface Props {
   onClose: () => void;
   item: Item;
   visible: boolean;
}

const AddonsModal = ({ onClose, item, visible }: Props) => {
   const { merchantDetails, addOrRemoveAddOn, toggleCustomization, update_other_customization, clearCustomization, clearAddOnsAndCustomization, getCartItem } = useApp();
   const { merchant_id } = merchantDetails;
   const { item_id } = item;
   const { currency } = merchantDetails;

   const path = useLocation().pathname;

   const addAddonToItem = (addon: AddOnItem) => {
      if (addon.in_stock) {
         addOrRemoveAddOn({ ...addon, item_id: item?.item_id }, "add");
      }
   };

   const removeAddonFromItem = (addon: AddOnItem) => {
      if (addon?.in_stock) {
         addOrRemoveAddOn({ ...addon, item_id: item?.item_id }, "remove");
      }
   };
   const toggleCustomizationToItem = (customization: Customization, option: string) => {
      toggleCustomization({ ...customization, item_id, option });
   };
   const clearSelection = (customization: Customization) => {
      clearCustomization({ ...customization, item_id: item?.item_id });
   };

   const updateOtherCustomization = (text: string) => {
      update_other_customization(item?.item_id, text);
   };

   const getCurrentCart = () => {
      const currentCart = getCartItem(item_id);
      return currentCart;
   };

   const getAddonCount = (add_on_id: string) => {
      return getCurrentCart()?.add_ons?.find((item) => item?.add_on_id === add_on_id)?.count || 0;
   };

   const closeModal = () => {
      const splittedPath = path.split("/");
      if (splittedPath[2] === "menu") {
         clearAddOnsAndCustomization(item?.item_id);
      }

      onClose();
   };

   const getCustomizationChecked = (customization: Customization, option: string) => {
      return (
         getCurrentCart()
            ?.customizations?.find((item) => item.customisation_id === customization.customisation_id)
            ?.selected_options?.includes(option) || false
      );
   };

   return (
      <Modal
         open={visible}
         onClose={closeModal}>
         <>
            <Slide
               direction="up"
               in={visible}>
               <div className="addons-modal">
                  <CloseIcon
                     style={{ fontSize: "2rem" }}
                     className="bg-[#fff] rounded-[1rem] p-2"
                     onClick={closeModal}
                  />
                  <div className="addons-modal-content ">
                     <ItemCard
                        cardType=""
                        item={item}
                        mid={merchant_id}
                        handleShowDetails={() => {}}
                     />

                     <div className="h-[70%] overflow-y-scroll pb-[3rem] scrollbar-hide">
                        {item.add_ons?.length > 0 && (
                           <div className="addons-addons">
                              <div className="addons-head font-custom">Choose add on items</div>
                              {item.add_ons.map((addon: AddOnItem, index) => (
                                 <div
                                    className={`addons-addon ${addon?.in_stock ? "opacity-1" : "opacity-50"}`}
                                    key={index}>
                                    <div>
                                       <div>{capitalizeFirstLetter(addon?.item_name)}</div>
                                       {!addon?.in_stock && <span className="text-[red] text-[0.8rem] font-bold">Out of stock</span>}
                                    </div>
                                    <div>
                                       {currency}
                                       {addon?.price}
                                    </div>
                                    {getAddonCount(addon?.add_on_id) > 0 ? (
                                       <div className="food-item-before-order-count-container">
                                          <Remove
                                             fontSize="small"
                                             onClick={() => removeAddonFromItem(addon)}
                                          />
                                          <div> {getAddonCount(addon?.add_on_id)} </div>
                                          <Add
                                             fontSize="small"
                                             onClick={() => addAddonToItem(addon)}
                                          />
                                       </div>
                                    ) : (
                                       <div
                                          className="food-item-before-order-add-button"
                                          onClick={() => addAddonToItem(addon)}>
                                          Add
                                       </div>
                                    )}
                                 </div>
                              ))}
                           </div>
                        )}
                        {item.customizations?.length > 0 && (
                           <div className="addons-customizations">
                              <div className="addons-head font-custom">Choose customizations</div>
                              {item.customizations.map((customization: Customization, index) => (
                                 <div
                                    className="addons-customization"
                                    key={index}>
                                    <div className="addons-customization-top">
                                       <div className="addons-customization-title font-custom">{capitalizeFirstLetter(customization?.customisation_title)}</div>
                                       <div
                                          className="clear-selection font-custom"
                                          onClick={() => clearSelection(customization)}>
                                          clear selection
                                       </div>
                                    </div>
                                    <div>
                                       {customization?.options?.map((option, index) => (
                                          <div
                                             className="addons-customization-top"
                                             key={index}>
                                             {option}
                                             {customization.selection_type === "radio" ? (
                                                <Radio
                                                   checked={getCustomizationChecked(customization, option)}
                                                   onChange={() => toggleCustomizationToItem(customization, option)}
                                                   name={customization.customisation_title}
                                                   value={option}
                                                />
                                             ) : (
                                                <Checkbox
                                                   checked={getCustomizationChecked(customization, option)}
                                                   onChange={() => toggleCustomizationToItem(customization, option)}
                                                   name={customization.customisation_title}
                                                   value={option}
                                                />
                                             )}
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              ))}
                           </div>
                        )}
                        <div className="addons-customization">
                           <div className="addons-customization-title font-custom">Add other customizations:</div>
                           <textarea
                              placeholder="Type here ..."
                              className="other-customization-input font-custom"
                              value={getCurrentCart()?.other_customization}
                              onChange={(e) => updateOtherCustomization(e.target.value)}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Slide>
            <div className="bg-[grey]">
               <ViewCartButton
                  onClickButton={onClose}
                  buttonTitle={"Confirm"}
               />
            </div>
         </>
      </Modal>
   );
};

export default AddonsModal;
