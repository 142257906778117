/** @format */

import { useApp } from "src/store/useApp";
import NonVegIcon from "../../asset/icons/NonVegIcon";
import VegIcon from "../../asset/icons/VegIcon";

export default function OrderItemsRow({ itemDetails, subdetails }) {
   const { merchantDetails } = useApp();
   const { currency } = merchantDetails;
   if (!itemDetails) {
      return null;
   }

   return (
      <div>
         <div className="grid grid-cols-custom items-center mb-2">
            <div>
               <span className="flex items-center text-base">
                  <span className="mr-1.5 w-[18px] h-[18px] flex-shrink-0 font-custom mt-1">{(itemDetails?.type === "veg" && <VegIcon />) || (itemDetails?.type === "non-veg" && <NonVegIcon />)}</span>
                  {itemDetails?.item_name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
               </span>
               <p className="text-base ml-4 font-custom">
                  {currency}
                  {itemDetails?.price}
               </p>

               <p className="text-xs ml-4 text-[#ACAFB0] font-custom">
                  {itemDetails?.customizations &&
                     itemDetails?.customizations?.map(
                        (customization) =>
                           customization?.customisation_id && (
                              <div key={customization?.customisation_id}>
                                 <span>
                                    {customization?.customisation_title}
                                    {" - "}
                                 </span>
                                 <span>
                                    {customization?.selected_options?.map((selectedOption) => (
                                       <span key={selectedOption}>{selectedOption}</span>
                                    ))}
                                 </span>
                              </div>
                           ),
                     )}

                  <p>{itemDetails?.other_customization}</p>
               </p>
            </div>
            <p className="text-[#0F6656] text-base font-custom font-medium text-center">x {itemDetails?.count}</p>
            <p className="text-base text-right font-custom font-medium">
               {currency}
               {itemDetails?.price}
            </p>
         </div>
         {itemDetails?.addons?.length > 0 ? (
            <div>
               {itemDetails?.addons?.map((addon, index) => (
                  <div className="">
                     <div className="grid grid-cols-fr mb-2 items-center pl-3">
                        <span className="text-[#0F6656] font-semibold font-custom ">Add on items</span>
                        <div className="w-[100%] bg-[#ebebeb] h-0.5"></div>
                     </div>
                     <div
                        key={index}
                        className="grid grid-cols-custom items-center mb-2">
                        <div className="pl-3">
                           <span className="flex items-center text-base">
                              <span className="mr-1.5 w-[18px] font-custom h-[18px]  flex-shrink-0">
                                 {(addon?.item_type === "veg" && <VegIcon />) || (addon?.item_type === "non-veg" && <NonVegIcon />)}
                              </span>
                              {addon?.item_name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                           </span>
                           <p className="text-base ml-4 font-custom">
                              {currency}
                              {addon?.price}
                           </p>
                        </div>
                        <p className="text-[#0F6656] font-custom text-base font-medium text-center">x {addon?.count}</p>
                        <p className="text-base text-right font-medium font-custom">
                           {currency}
                           {addon?.price}
                        </p>
                     </div>
                  </div>
               ))}
            </div>
         ) : (
            ""
         )}
      </div>
   );
}
