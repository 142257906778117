/** @format */

import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIsAuthenticated } from "src/hooks/useIsAuthenticated";
import { useApp } from "src/store/useApp";
import { getLoyaltyPointsAPI } from "../../api/loyalty/loyaltyAPIs";
import FacebookIcon from "../../asset/icons/FacebookIcon";
import ForwardArrowIcon from "../../asset/icons/ForwardArrow";
import GoogleIcon from "../../asset/icons/GoogleIcon";
import InstagramIcon from "../../asset/icons/InstragramIcon";
import LogoutIcon from "../../asset/icons/LogoutIcon";
import LoyaltyIcon from "../../asset/icons/LoyaltyIcon";
import OrdersIcon from "../../asset/icons/OrdersIcon";
import ProfileIcon from "../../asset/icons/ProfileIcon";
import ShareIcon from "../../asset/icons/ShareIcon";
import TwitterIcon from "../../asset/icons/TwitterIcon";
import YoutubeIcon from "../../asset/icons/YoutubeIcon";
import LoginIcon from "../../asset/icons/loginIcon";
import BecomeOurMember from "../BecomeOurMember";

interface CustomDrawerProps {
   open: boolean;
   toggleDrawer: () => void;
}

export default function CustomDrawer({ open, toggleDrawer }: CustomDrawerProps) {
   const navigate = useNavigate();
   const { mid, qid } = useParams();
   const { merchantDetails } = useApp();

   const isAuthenticated = useIsAuthenticated();

   const { loyalty_enabled: isLoyaltyEnabled } = merchantDetails;
   const [isSubscribedToLoyalty, setisSubscribedToLoyalty] = useState(false);

   const handleShareStore = () => {
      navigator.share({
         title: "Share this store",
         text: "Share this store",
         url: qid !== undefined ? `${window.location.origin}/${mid}/${qid}` : `${window.location.origin}/${mid}`,
      });
   };

   const DRAWER_ITEMS = [
      {
         name: "Log in",
         icon: <LoginIcon />,
         showArrow: true,
         navigate: qid != undefined ? `/${mid}/${qid}/login` : `/${mid}/login`,
      },
      {
         name: "Exclusive Club",
         icon: (
            <span className="drawer-menu-icon-wrapper">
               <LoyaltyIcon />
            </span>
         ),
         isLoggedIn: true,
         showArrow: true,
         navigate: qid !== undefined ? `/${mid}/${qid}/loyaltyprogram` : `/${mid}/loyaltyprogram `,
      },
      {
         name: "Orders",
         icon: (
            <span className="drawer-menu-icon-wrapper ">
               <OrdersIcon />
            </span>
         ),
         isLoggedIn: true,
         showArrow: true,
         navigate: qid !== undefined ? `/${mid}/${qid}/allorders` : `/${mid}/allorders`,
      },

      {
         name: "About us",
         icon: (
            <span className="drawer-menu-icon-wrapper ">
               <ProfileIcon />
            </span>
         ),
         showArrow: true,
         navigate: "/about-us",
      },

      {
         name: "Share this store",
         icon: (
            <span className="drawer-menu-icon-wrapper ">
               <ShareIcon />
            </span>
         ),
         showArrow: true,
         handleClick: handleShareStore,
      },
   ];

   const SOCIAL_LINKS = [
      {
         icon: <InstagramIcon />,
         href: merchantDetails?.instagram_url,
      },
      {
         icon: <TwitterIcon />,
         href: merchantDetails?.twitter_url,
      },
      {
         icon: <FacebookIcon />,
         href: merchantDetails?.facebook_page_url,
      },
      {
         icon: <GoogleIcon />,
         href: merchantDetails?.google_reviews_link,
      },
      {
         icon: <YoutubeIcon />,
         href: merchantDetails?.youtube_url,
      },
   ];
   console.log(merchantDetails?.instagram_url, "insta url");

   const hideLoyaltyProfileTile = (item: any) => {
      if (item?.name === "Exclusive Club") {
         if (isAuthenticated === null) {
            return true;
         } else if (merchantDetails?.loyalty_enabled) {
            if (isSubscribedToLoyalty) {
               return false;
            } else {
               return true;
            }
         } else {
            return true;
         }
      }
   };

   const data = isAuthenticated ? DRAWER_ITEMS.slice(1) : DRAWER_ITEMS.filter((item) => !item.isLoggedIn);

   const list = () => (
      <div className="h-[100vh] flex flex-col justify-between">
         <Box
            sx={{
               width: "85vw",
               overflow: "scroll",
               borderRadius: "10px",
            }}
            role="presentation"
            onKeyDown={toggleDrawer}>
            <Box
               className="flex flex-col items-center justify-evenly gap-2"
               width={"100%"}
               height={"20vh"}>
               <div className="flex items-center font-custom flex-col justify-center gap-2">
                  <span>
                     {merchantDetails?.image_url && merchantDetails?.image_url !== null ? (
                        <Avatar
                           sx={{
                              width: "75px",
                              height: "75px",
                           }}
                           src={merchantDetails?.image_url}
                        />
                     ) : (
                        <Avatar
                           sx={{
                              width: "60px",
                              height: "60px",
                              fontSize: "28px",
                              fontFamily: "TWKEverett,san-serif",
                           }}>
                           {/* {merchantDetails?.restaurant_name[0]?.toUpperCase()} */}
                        </Avatar>
                     )}
                  </span>
                  <span className="font-bold font-custom text-[20px]">{merchantDetails?.restaurant_name}</span>
               </div>
               {isAuthenticated !== null && (
                  <span className="font-custom flex justify-center gap-[1.8rem] w-[70%]">
                     {SOCIAL_LINKS?.map((item: any) => {
                        if (item?.href !== null) {
                           return (
                              <div
                                 onClick={() => {
                                    if (item?.href !== null) {
                                       window.open(item?.href, "_blank");
                                    }
                                 }}
                                 className="font-custom"
                                 style={{
                                    display: item?.href === "" ? "none" : "flex",
                                    width: "20px",
                                    height: "20px",
                                 }}>
                                 {item?.icon}
                              </div>
                           );
                        }
                     })}
                  </span>
               )}
            </Box>
            <div className="w-[100%] flex mt-2 items-center justify-center">
               <Divider className="h-[1px] w-[80%] bg-[#E7E8E8]" />
            </div>
            <List>
               {data?.map((item: any) => {
                  if (hideLoyaltyProfileTile(item)) return null;
                  return (
                     <ListItem
                        disabled={item?.name === "Loyalty profile" && !isLoyaltyEnabled}
                        onClick={(e) => {
                           e.stopPropagation();
                           if (item.name === "About us") {
                              window.open("https://www.thebeautifulmenu.com/about-us", "_blank");
                           } else if (item?.handleClick) {
                              item?.handleClick();
                           } else {
                              navigate(item?.navigate);
                           }
                        }}>
                        <ListItemButton
                           disabled={item?.name === "Loyalty profile" && !isLoyaltyEnabled}
                           href={item?.href}>
                           <ListItemIcon>{item.icon}</ListItemIcon>
                           <ListItemText>
                              <span className="font-custom font-bold text-[18px] text-[#0F191A]">{item?.name}</span>
                           </ListItemText>
                           {item?.showArrow && <ForwardArrowIcon />}
                        </ListItemButton>
                     </ListItem>
                  );
               })}
            </List>
            <Divider />
         </Box>
         <div className="flex flex-col gap-1">
            {!isSubscribedToLoyalty && merchantDetails?.loyalty_enabled && <BecomeOurMember />}
            {isAuthenticated && (
               <ListItem
                  sx={{
                     bottom: "10px",
                  }}
                  onClick={async (e) => {
                     e.stopPropagation();
                     window.location.reload();
                     await localStorage.removeItem("token");
                  }}>
                  <ListItemButton>
                     <ListItemIcon>
                        <span className="drawer-menu-icon-wrapper ">
                           <LogoutIcon />
                        </span>
                     </ListItemIcon>
                     <span className="font-[Everett] font-bold text-[18px] text-[#0F191A]">Sign out</span>
                  </ListItemButton>
               </ListItem>
            )}
         </div>
      </div>
   );

   const fetchLoyaltyPoints = async () => {
      try {
         const response = await getLoyaltyPointsAPI(isAuthenticated, mid);
         if (response?.status === 200) {
            setisSubscribedToLoyalty(true);
         }
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      fetchLoyaltyPoints();
   }, []);

   return (
      <Drawer
         sx={{
            borderTopRightRadius: "10px",
         }}
         PaperProps={{
            sx: {
               borderTopRightRadius: "10px",
               borderBottomRightRadius: "10px",
            },
         }}
         anchor={"left"}
         open={open}
         onClose={toggleDrawer}>
         <>{merchantDetails && list()}</>
      </Drawer>
   );
}
