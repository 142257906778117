/** @format */

import { useState, useEffect } from "react";

export const useIsAuthenticated = (): boolean => {
   const [isAuthenticated, setisAuthenticated] = useState(false);

   useEffect(() => {
      const jwt = localStorage.getItem("token");
      if (jwt !== null) {
         setisAuthenticated(true);
      } else {
         setisAuthenticated(false);
      }
   }, []);

   return isAuthenticated;
};
