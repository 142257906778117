/** @format */

import { Offer } from "src/interface/offer";
import { useAppProps, useOffersProps } from "./storeTypes";
import { useApp } from "./useApp";
import { GetAPI } from "src/api/request";
import { EndPoints } from "src/api/endPoints";

type SetState = (partial: Partial<useAppProps> | ((state: useAppProps) => Partial<useAppProps>), replace?: boolean) => void;

export const useOffers = (set: SetState, get: () => useAppProps): useOffersProps => {
   const offers: Offer[] = get()?.offers;

   return {
      offers: [] as Offer[],
      appliedOffer: "",
      setOffers: () => {
         const { merchant_id } = get().merchantDetails;
         GetAPI(EndPoints.offersEndPoints.Offers(merchant_id)).then((res: any) => {
            set({ offers: res?.data?.offers });
         });
      },
      onApplyOffer: (appliedOffer) => set({ appliedOffer }),
      onRemoveOffer: () => set({ appliedOffer: "" }),
      getOfferById: (offer_id) => {
         const { offers } = get();
         return offers?.find((offer) => offer.offer_id === offer_id) || ({} as Offer);
      },
      getNearestOffer: () => {
         const { offers, getCartTotal } = get();
         const amount = getCartTotal();
         if (offers?.length > 0) {
            let offer = offers?.reduce((prev, curr) => (Math.abs(curr.offer_value - amount) < Math.abs(prev?.offer_value - amount) ? curr : prev));
            if (offer) {
               return offer;
            } else {
               return {} as Offer;
            }
         } else {
            return {} as Offer;
         }
      },
      isOfferTypeIsPercentage: (offer_id: string) => {
         const { getOfferById } = get();
         if (getOfferById(offer_id)?.discount_type === "percentage") {
            return true;
         } else {
            return false;
         }
      },
      setAutoApplyOffer: () => {
         const { offers, getNearestOffer, getCartTotal } = get();

         const nearestOffer = getNearestOffer();
         const amount = getCartTotal();

         offers?.forEach((offer) => {
            if (offer?.bool_exp === "greater_than_or_equal_to") {
               if (offer?.offer_value <= amount && offer?.offer_value === nearestOffer?.offer_value) {
                  set({ appliedOffer: offer?.offer_id });
               }
            } else if (offer?.bool_exp === "greater_than") {
               if (offer?.offer_value < amount && offer?.offer_value === nearestOffer?.offer_value) {
                  set({ appliedOffer: offer?.offer_id });
               }
            }
         });
      },
      setAutoRemoveOffer: () => {
         const { appliedOffer, getOfferById, getCartTotal } = get();
         const cartTotal = getCartTotal();
         if (appliedOffer != "") {
            if (getOfferById(appliedOffer)?.offer_value > cartTotal) {
               set({ appliedOffer: "" });
            }
         } else {
            return;
         }
      },
      isOfferApplicable: (offer) => {
         const amount = get().getCartTotal();
         if (offer?.bool_exp === "greater_than") {
            return amount > offer?.offer_value;
         } else if (offer?.bool_exp === "greater_than_or_equal_to") {
            return amount >= offer?.offer_value;
         } else {
            return false;
         }
      },
      getMaximumDiscount: (offer_id) => {
         const { getCartTotal, getOfferById } = get();
         const amount = getCartTotal();
         let total = 0;
         if (getOfferById(offer_id)?.discount_type === "percentage") {
            total = Math.min(((getOfferById(offer_id) as Offer)?.discount_val / 100) * amount, (getOfferById(offer_id) as Offer)?.maximum_allowed_discount);
         } else {
            total = (getOfferById(offer_id) as Offer)?.discount_val;
         }
         if (total === null || total === undefined) {
            return 0;
         } else {
            return total;
         }
      },
      getOfferAppliedAmount: () => {
         const { appliedOffer, getMaximumDiscount } = get();
         return getMaximumDiscount(appliedOffer);
      },
      checkAndApplyOrRemoveOffer: () => {
         const { setAutoApplyOffer, setAutoRemoveOffer } = get();
         setAutoApplyOffer();
         setAutoRemoveOffer();
      },
      resetOffer: () => {
         set({
            offers: [] as Offer[],
            appliedOffer: "",
         });
      },
   };
};
