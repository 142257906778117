/** @format */

import { SubOrder } from "src/interface/order";
import { Merchant } from "src/interface/merchant";
import { BillDetails } from "src/interface/billIDetails";
import { CGST_PERCENTAGE, SGST_PERCENTAGE } from "src/utils/definition";

export const getOverallOfferAmount: (allSubOrders: SubOrder[]) => number = (allSubOrders) => {
   let overallOfferAmount = 0;
   allSubOrders.map((item) => {
      overallOfferAmount += item.offers_discount_amount;
   });
   return overallOfferAmount;
};

export const getOverallLoyaltyAmount: (allSubOrders: SubOrder[]) => number = (allSubOrders) => {
   let overallLoyaltyAmount = 0;
   allSubOrders.map((item) => {
      overallLoyaltyAmount += item.loyalty_discount_amount;
   });
   return overallLoyaltyAmount;
};

export const getTotalDiscountedAmount = (subOrder: SubOrder) => subOrder.loyalty_discount_amount + subOrder.offers_discount_amount;

export const getBillDetails: (allSubOrders: SubOrder[], merchantDetails: Merchant) => BillDetails = (allSubOrders, merchantDetails) => {
   const { include_gst, currency } = merchantDetails;

   let total_amount: number = 0;
   let totalDiscountAmount: number = 0;

   allSubOrders.map((item: SubOrder) => {
      console.log(item);
      total_amount += item.total_suborder_amount;
      totalDiscountAmount += getTotalDiscountedAmount(item);
   });

   let total_amount_after_discount: number = total_amount - totalDiscountAmount;
   let amount_after_taxes: number = 0;
   let rounded_off: number = 0;

   let cgst_percentage = include_gst ? CGST_PERCENTAGE : 0;
   let sgst_percentage = include_gst ? SGST_PERCENTAGE : 0;

   if (include_gst) {
      amount_after_taxes = total_amount_after_discount + (total_amount * (cgst_percentage * 0.01) + total_amount_after_discount * (sgst_percentage * 0.01));
   } else {
      amount_after_taxes = total_amount_after_discount;
   }

   if (currency === "INR") {
      let round_off_amount = Math.round(amount_after_taxes);
      rounded_off = round_off_amount - amount_after_taxes;
      amount_after_taxes = round_off_amount;
   }

   return {
      amount_after_taxes: Number(amount_after_taxes.toFixed(2)),
      cgst_percentage: cgst_percentage.toString(),
      sgst_percentage: sgst_percentage.toString(),
      rounded_off: Number(rounded_off.toFixed(2)),
      total_amount: Number(total_amount.toFixed(2)),
      order_discount: totalDiscountAmount,
      total_amount_after_discount,
   };
};
