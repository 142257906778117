/** @format */

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { useAppProps } from "./storeTypes";
import { useCart } from "./useCart";
import { useLoyalty } from "./useLoyalty";
import { useMerchantDetails } from "./useMerchantDetails";
import { useOffers } from "./useOffers";
import { useOrder } from "./useOrder";
import { useMenu } from "./useMenuProps";

export const useApp = create<useAppProps>()(
   devtools(
      persist(
         (set, get) => {
            return {
               ...useMerchantDetails(set, get),
               ...useOffers(set, get),
               ...useCart(set, get),
               ...useLoyalty(set, get),
               ...useOrder(set, get),
               ...useMenu(set, get),
            };
         },
         {
            name: "tbm",
            getStorage: () => localStorage,
         },
      ),
   ),
);
