/** @format */

import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import getItemRecommender from "src/api/menu/getItemRecommender";
import ItemCard from "src/component/ItemCard";
import { Item } from "src/interface/item";
import CategoryCard from "../CategoryCard";
import { useAppNavigate } from "src/hooks/useAppNavigate";

interface ItemTabContentProps {
   searchQuery: string;
   data: any;
   currentTab: string;
}

const ItemTabContent: React.FC<ItemTabContentProps> = ({ searchQuery, currentTab, data }) => {
   const { mid, qid } = useParams<{ mid: string; qid: string }>();
   const [toggledId, settoggledId] = useState("");
   const [recentlyClickedItem, setrecentlyClickedItem] = useState("");
   const navigate = useNavigate();
   const appNavigate = useAppNavigate();

   const handleUpdateToggle = (id: any) => {
      settoggledId((prevId) => (prevId === id ? "" : id));
      setrecentlyClickedItem("");
   };

   const getPeopleAlsoOrdered = async (data: any) => {
      console.log(data, "DATA getPeopleAlsoOrdered");
      setrecentlyClickedItem(data.item_id);

      const request = await getItemRecommender({
         merchantId: data.merchant_id,
         itemId: data.item_id,
         cookieId: data.cookie_id,
         activity: "added_to_cart",
      });
      const payload: any = request.payload;

      if (request.statusCode === 200) {
         if (payload?.response?.length === 0) {
            toast("No items to recommend");
         }
         window.localStorage.setItem("peopleAlsoOrdered", JSON.stringify(payload.response));
         window.localStorage.setItem("recentlyClickedItem", data.item_id);
      }
      console.log(payload, "RESPONSE getItemRecommender");
   };

   const onClick = (link: string) => {
      appNavigate({ path: `/menu/${link}` });
   };

   const isItemsExistInSearch = (category_name: string) => {
      const category = data.find((item: any) => item.text === category_name);
      return category ? category.items.some((item: Item) => item.item_name.toLowerCase().includes(searchQuery.toLowerCase())) : false;
   };

   const filteredData = useMemo(() => {
      return data?.filter((item: any) => {
         const showCategories = searchQuery !== "" ? item.text.toLowerCase().includes(searchQuery.toLowerCase()) || isItemsExistInSearch(item.text) : true;
         return showCategories;
      });
   }, [data, searchQuery]);

   const renderItemTabContent = () => {
      if (currentTab === "all") {
         return (
            <div className="flex flex-col gap-2">
               {filteredData?.map((item: any) => (
                  <React.Fragment key={item.text}>
                     <div
                        className="menu-card-menu-item"
                        onClick={() => onClick(item.link)}>
                        <CategoryCard
                           background={item.background}
                           text={item.text}
                           link={item.link}
                        />
                     </div>
                     {searchQuery != "" &&
                        item?.items?.map((item: Item) => {
                           if (item.item_name.toLowerCase().includes(searchQuery.toLowerCase())) {
                              return (
                                 <ItemCard
                                    key={item?.item_id}
                                    cardType="details"
                                    mid={mid ?? ""}
                                    item={item}
                                 />
                              );
                           }
                           return null;
                        })}
                  </React.Fragment>
               ))}
            </div>
         );
      } else {
         return (
            <>
               {data.map((item: Item) => (
                  <ItemCard
                     key={item?.item_id}
                     cardType="details"
                     mid={mid ?? ""}
                     item={item}
                  />
               ))}
            </>
         );
      }
   };

   return <>{renderItemTabContent()}</>;
};

export default ItemTabContent;
