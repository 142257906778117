/** @format */

import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApp } from "src/store/useApp";
import sendOtp from "../../api/otp/sendOtp";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import Loader from "../../component/Loader";
import "./style.scss";

const LoginPage = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const { mid, qid }: any = useParams();
   const [formState, setFormState] = useState({
      // user_name: '',
      phone_number: "",
      email: "",
      save: true,
      pincode: "",
      merchant_id: mid,
   });
   const [isLoading, setIsLoading] = useState(false);

   const { within_india: withinIndia } = useApp().merchantDetails;

   const handleInputChange = (value: string | boolean, stateName: string) => {
      console.log("HANFLEINPUR CHANGE", value);

      setFormState({
         ...formState,
         [stateName]: value,
      });
   };
   console.log(formState);

   const onSubmitHandler = async () => {
      let body: any = {
         save: formState.save,
         merchant_id: mid,
      };

      if (withinIndia) {
         if (formState.phone_number.length === 10) {
            body.phone_number = formState.phone_number;
         } else {
            toast.error("Please enter a valid phone number.");
            return;
         }
      } else {
         if (formState.email.includes("@")) {
            body.email = formState.email;
         } else {
            toast.error("Please enter a valid email address.");
            return;
         }
      }

      setIsLoading(true);

      const { payload, statusCode } = await sendOtp(body);
      if (statusCode === 200) {
         setIsLoading(false);
         localStorage.setItem("phone_number", formState.phone_number);
         localStorage.setItem("email", formState.email);
         if (qid !== undefined) {
            navigate(`/${mid}/${qid}/otp`, { state: body });
         } else {
            navigate(`/${mid}/otp`, { state: body });
         }
      } else {
         toast.error("Failed to send OTP. Please try again.");
         setIsLoading(false);
      }
   };

   const handleBackButtonClick = () => {
      // @ts-ignore
      if (location?.state?.from?.pathname) {
         navigate(`/${mid}`);
      } else {
         navigate(-1);
      }
   };

   function RedBar() {
      return (
         <Box
            sx={{
               height: 15,
               backgroundColor: (theme) => (theme.palette.mode === "light" ? "rgba(255, 0, 0, 0.1)" : "rgb(255 132 132 / 25%)"),
            }}
         />
      );
   }

   const useStyles = makeStyles({
      root: {
         "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A4A8A9",
            borderRadius: 10,
         },
         "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffffff",
         },
         "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0F6656",
         },
         "& .MuiOutlinedInput-input": {
            color: "#0F191A",
            borderStyle: "solid",
            borderRadius: 10,
         },
         "&:hover .MuiOutlinedInput-input": {
            color: "#ffffff",
         },
         "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#0F191A", //number
            fontWeight: "600",
         },
         "& .MuiInputLabel-outlined": {
            color: "#A4A8A9",
            fontWeight: "600",
            fontFamily: "TWK Everett",
         },
         "&:hover .MuiInputLabel-outlined": {
            color: "#A4A8A9",
         },
         "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#0F6656",
         },
      },
   });
   const classes = useStyles();

   function isPhonenumberValid() {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (formState.phone_number.match(phoneno)) {
         return true;
      } else {
         return false;
      }
   }

   return (
      <div className="bg-[#ffff] h-screen w-screen ">
         <Loader Load={isLoading} />
         <Toaster position="top-center" />
         <div className="flex items-center p-2 gap-3 pt-4">
            <span onClick={handleBackButtonClick}>
               <BackArrow />
            </span>
         </div>
         <h2 className="text-3xl font-bold mt-3 mb-2 ps-2 custom-font">Sign in and satisfy your hunger!</h2>
         <p className="text-base text-neutral-400 font-normal px-2 custom-font mb-2.5">Enter your {withinIndia ? "phone number" : "email"} and get OTP</p>
         <div className="h-[20%] flex flex-col justify-center px-2">
            {withinIndia ? (
               <TextField
                  autoFocus={true}
                  required
                  id="phone_number"
                  label="Phone number"
                  variant="outlined"
                  onChange={(e: any) => handleInputChange(e.target.value, "phone_number")}
                  type="number"
                  value={formState.phone_number}
                  className={classes.root}
               />
            ) : (
               <TextField
                  autoFocus={true}
                  required
                  id="email_address"
                  label="Email address "
                  variant="outlined"
                  onChange={(e: any) => handleInputChange(e.target.value, "email")}
                  type="email"
                  value={formState.email}
                  className={classes.root}
               />
            )}
            <div className="flex items-center mt-3 gap-2">
               <Checkbox
                  checked={formState.save}
                  onChange={(e) => handleInputChange(!formState.save, "save")}
                  style={{
                     borderRadius: "10px",
                  }}
                  sx={{
                     color: "#A4A8A9",
                     borderRadius: "1rem",
                     background: "white",
                     height: "1.5rem",
                     width: "1.5rem",
                     borderColor: "black",
                     "&.Mui-checked": {
                        color: "#0F6656",
                        borderRadius: "1rem",
                     },
                  }}
               />
               <p className="text-[#0F191A] font-medium my-2 custom-font">Subscribe to get exclusive tasty updates.</p>
            </div>
         </div>

         <div className="flex flex-col items-center mt-2 px-2">
            <button
               className={`${
                  (isPhonenumberValid() && formState.phone_number.length >= 10) || formState.email.includes("@") ? "bg-[#0F6656] text-[#ffffff]" : "bg-[#0F191A1A] text-[#0F191A80]"
               } h-[4rem] w-[100%]  rounded-xl text-[1.4rem]  font-semibold`}
               onClick={onSubmitHandler}>
               Get OTP
            </button>
            <div className="w-[100%] text-[#0F191A] mt-3 custom-font">
               By clicking you, I agree to the{" "}
               <a
                  target="_blank"
                  className="underline font-semibold"
                  href="https://www.thebeautifulmenu.com/terms-and-conditions">
                  Terms and conditions.
               </a>
            </div>
         </div>
      </div>
   );
};

export default LoginPage;
