/** @format */

import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { EndPoints } from "src/api/endPoints";
import { GetAPI } from "src/api/request";
import SingleOrderDetailsPageSkeleton from "src/component/Skeletons/SingleOrderDetailsPageSkeleton";
import updateOrderAPI from "../../api/order/updateOrder";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import RightArrowBlack from "../../asset/icons/RightArrowBlack";
import DineInOrderCard from "../../component/DineInOrderItemsCard";
import { addMoreItemOnCartActions } from "../../store/addMoreItemOnOrder/reducer";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { orderActions } from "../../store/order/orderSlice";
import { updateOrderCartActions } from "../../store/updateOrderCart/reducer";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import userSelectors from "../../store/user/selectors";
import OrderDetailsSkeleton from "../OrderDetailsPage/OrderDetailsSkeleton";
import OrderDetailsSection from "./OrderDetailsSection";
import OrderStatusPage from "./OrderStatusPage";
import "./style.scss";
const HEIGHT = window.innerHeight;

const SingleOrderDetailPage = (props: any) => {
   const navigate = useNavigate();
   const { orderId } = useParams();
   const dispatch = useAppDispatch();
   const REACT_APP_RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
   const [refundModal, setrefundModal] = useState(false);
   const Razorpay = useRazorpay();
   const { mid, qid }: any = useParams();
   const [orderPlacedModal, setorderPlacedModal] = useState(false);
   console.log("QR CODE ID => ", qid);
   const [navigateToOrderStatus, setnavigateToOrderStatus] = useState(true);

   const { cart, totalValue, itemCount } = useAppSelector(cartItemsSelector.getAllCartInfo(mid));
   const [newlyAddedItems, setnewlyAddedItems] = useState(0);
   const { customer_id } = useAppSelector(userSelectors.getUserDetails);
   const { jwt: accessToken }: any = useAppSelector(userSelectors.getAccessToken);
   const { isUpdating } = useAppSelector(updateOrderCartSelectors.getAllUpdateOrderCartInfo);
   const countRef = useRef(0);
   const location: any = useLocation();

   const fetchOrder = () => {
      return GetAPI(EndPoints.orderEndPoints.Order(orderId as string)).then((res: any) => {
         return res?.data;
      });
   };

   const { data: orderDetails, isLoading } = useQuery({
      queryKey: ["orders", orderId],
      queryFn: fetchOrder,
      refetchInterval: 30000,
   });

   console.log("ORDERR", orderDetails);

   const existingInStockOrders = orderDetails?.items_ordered?.filter((item: any) => !item.out_of_stock);

   const NavigateToOrderStatusPage = () => {
      setnavigateToOrderStatus((p) => !p);
   };

   let updateOrderPayload = orderDetails;

   const RetryPayment = async () => {
      const amountToPay = orderDetails?.extra_payment_amount > 0 ? orderDetails?.extra_payment_amount : orderDetails?.total_amount_after_discount;

      const amount_paid = amountToPay + orderDetails?.amount_paid;
      const options: any = {
         key: REACT_APP_RAZORPAY_KEY_ID,
         ...orderDetails.payment_gateway_details,
         amount: (amountToPay * 100).toString(),
         handler: async (response: any) => {
            updateOrderPayload = {
               ...orderDetails,
               payment_order_id: response.razorpay_payment_id,
               payment_status: "paid",
               extra_payment_amount: 0,
               extra_payment: false,
               amount_paid: amount_paid,
               payment_made_through_payment_gateway: true,
            };
            const updateOrderResponse: any = await updateOrderAPI(updateOrderPayload, accessToken);

            if (updateOrderResponse.statusCode === 200) {
               window.location.reload();
            }
         },
      };

      const rzp1 = new Razorpay(options);
      rzp1.open();
   };

   const onPressButton = () => {
      // if (orderDetails?.payment_status === 'paid') {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}`);
      } else {
         navigate(`/${mid}`);
      }
      // }
   };

   useEffect(() => {
      if (location?.state?.fromDinein || location?.state?.fromPayment) {
         setorderPlacedModal(true);
         setTimeout(() => {
            setorderPlacedModal(false);
         }, 6000);
      }
   }, []);

   const onPressBack = () => {
      const BACKLINK = qid !== undefined ? `/${mid}/${qid}` : `/${mid}`;
      if (location?.state?.fromDinein) {
         navigate(BACKLINK);
      } else {
         navigate(-1);
      }
   };
   console.log(location, "location on single order details");

   const onAddMoreItems = () => {
      dispatch(
         addMoreItemOnCartActions.initialise({
            confirmedItems: orderDetails.items_ordered,
            totalAmount: orderDetails.total_amount_after_discount,
            orderId: orderDetails.order_id,
         }),
      );

      console.log("QID onAddMoreItems", qid);

      navigate(qid !== undefined ? `/${mid}/${qid}` : `/${mid}`, {
         state: { orderDetails: orderDetails },
      });
   };

   const totalItemsCount = (order: any) => {
      console.log(order, "order");
      let Count = 0;
      for (let i = 0; i < order.items_ordered?.length; i++) {
         if (order.items_ordered[i]?.suborder_status !== "cancelled") {
            Count = Count + order.items_ordered[i]?.suborder_items_ordered?.length;
         }
      }
      console.log(Count, "Counttt");
      return Count;
   };

   const onClickViewOrderStatus = () => setnavigateToOrderStatus(true);

   return (
      <>
         <SingleOrderDetailsPageSkeleton isVisible={isLoading} />
         <div
            hidden={isLoading}
            className="w-full h-full">
            {!navigateToOrderStatus ? (
               <div className="single-order-details-container">
                  <Toaster
                     position="top-center"
                     containerStyle={{ zIndex: 9999, marginTop: `3rem` }}
                  />
                  <ToastContainer
                     style={{
                        zIndex: 9999,
                     }}
                     hideProgressBar={true}
                     position="bottom-center"
                  />
                  <div className="order-summary-header">
                     <div onClick={NavigateToOrderStatusPage}>
                        <BackArrowIcon />
                     </div>
                     <div className="order-status-header-text-container">
                        <div className="order-summary-header-text">{"Order id: " + orderId}</div>
                        <div
                           className="view-order-status-container"
                           onClick={onClickViewOrderStatus}>
                           <div className="view-order-status-text">View order status</div>
                           <RightArrowBlack />
                        </div>
                     </div>
                     <div></div>
                  </div>
                  <div style={{ paddingTop: "5rem" }}>
                     <OrderDetailsSection
                        onPressChange={() => {}}
                        totalItems={orderDetails?.order_type === "Dine-in" ? totalItemsCount(orderDetails) : orderDetails?.items_ordered?.filter((item: any) => !item.out_of_stock).length}
                        totalAmount={orderDetails?.total_amount}
                        totalDiscount={orderDetails?.order_discount}
                        grandTotal={orderDetails?.amount_after_taxes}
                        refundAmount={orderDetails?.refund}
                        paymentStatus={orderDetails?.payment_status}
                        orderType={orderDetails?.order_type}
                        orderStatus={orderDetails?.order_status}
                        showChange={false}
                        extraPaymentAmount={orderDetails?.extra_payment_amount}
                        cgst={orderDetails?.cgst_percentage}
                        sgst={orderDetails?.sgst_percentage}
                        rounded_off={orderDetails?.rounded_off}
                     />
                     <div className="single-order-details-orders-div">
                        <div className="single-order-details-border"></div>
                        {existingInStockOrders?.length > 0 ? (
                           <div className="single-order-details-list">
                              <div className="active-cards-single-order-page-container">
                                 {orderDetails?.order_type === "Dine-in" ? (
                                    existingInStockOrders?.map((item: any, index: number) => {
                                       return (
                                          <>
                                             <DineInOrderCard
                                                orderDetails={orderDetails}
                                                index={index}
                                                item={item}
                                             />
                                          </>
                                       );
                                    })
                                 ) : (
                                    <>
                                       {existingInStockOrders?.map((item: any, index: number) => {
                                          return (
                                             <>
                                                <DineInOrderCard
                                                   orderDetails={orderDetails}
                                                   index={index}
                                                   item={item}
                                                />
                                             </>
                                          );
                                       })}
                                    </>
                                 )}
                              </div>
                           </div>
                        ) : null}
                     </div>
                     <div className="single-order-page-button-wrapper">
                        <div
                           onClick={onPressButton}
                           className="single-order-details-bottom-container-btn-go-to-menu">
                           Go to menu
                        </div>
                     </div>
                  </div>
               </div>
            ) : (
               <>
                  <OrderStatusPage
                     orderPlacedModal={orderPlacedModal}
                     orderId={orderId}
                     NavigateToOrderStatusPage={onPressBack}
                     NavigateToOrderDetailsPage={NavigateToOrderStatusPage}
                     orderDetails={orderDetails}
                     setorderPlacedModal={setorderPlacedModal}
                     onAddMoreItems={onAddMoreItems}
                     RetryPayment={RetryPayment}
                  />
               </>
            )}
         </div>
      </>
   );
};

export default SingleOrderDetailPage;
