/** @format */

import React from "react";
import { useApp } from "src/store/useApp";
import CouponCard from "src/component/CouponCard";
import NoOffer from "../../asset/svg/OffersScreen/NoOffer.png";
import { useNavigate } from "react-router-dom";
import { Offer } from "src/interface/offer";
import { ReactComponent as GoBackArrow } from "../../asset/svg/OffersScreen/GoBackArrow.svg";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";

export default function Offers() {
   const { offers, merchantDetails, appliedOffer, onApplyOffer, isOfferTypeIsPercentage, getMaximumDiscount, isOfferApplicable, onRemoveOffer, setAutoRemoveOffer } = useApp();
   const { currency } = merchantDetails;

   const navigate = useNavigate();

   const goBack = () => navigate(-1);

   const onApplyingOffer = (offer_id: string) => {
      onApplyOffer(offer_id);
      goBack();
   };

   return (
      <>
         <div className="checkout-header sticky top-0 p-2 bg-[#fff] gap-[20px]">
            <div className="w-[100%] py-2 flex items-center">
               <div
                  className="mr-[10px] ring-1 rounded-[50px] ring-[#C3C5C6]"
                  onClick={goBack}>
                  <BackArrowIcon />
               </div>
               <div className="menupage-header-text text-[1.2rem] font-custom font-semibold flex flex-col gap-1">Offers</div>
            </div>
         </div>

         <div className="d-flex flex-column w-full justify-content-center align-items-stretch gap-2 px-4">
            {offers?.length > 0 ? (
               <>
                  {offers?.map((coupon: Offer, index) => {
                     return (
                        <CouponCard
                           key={coupon?.offer_id}
                           code={coupon?.offer_name}
                           description={
                              `${isOfferTypeIsPercentage(coupon?.offer_id) ? `${coupon?.discount_val}%` : `${currency}${coupon?.discount_val}`}` +
                              ` Off on orders above ${currency}${coupon?.offer_value}`
                           }
                           savings={"You saved " + currency + getMaximumDiscount(coupon?.offer_id) + " on this order"}
                           isApplicable={isOfferApplicable(coupon)}
                           isApplied={appliedOffer === coupon?.offer_id}
                           applyCoupon={() => onApplyingOffer(coupon.offer_id)}
                           removeCoupon={onRemoveOffer}
                        />
                     );
                  })}
               </>
            ) : (
               <div className="flex flex-col items-center ">
                  <img
                     src={NoOffer}
                     alt="NoOffer"
                  />
                  <p className="text-2xl font-semibold text-center mb-2">No coupons right now!</p>
                  <p className="text-base text-center mb-5">There is no coupons available at this moment. Come back later for more offers</p>
                  <button
                     onClick={goBack}
                     className="flex items-center gap-x-2 mx-auto bg-[#F3F7F6] font-semibold text-[#0F6656] px-6 py-2 rounded-full">
                     <span className="w-5">
                        <GoBackArrow />
                     </span>{" "}
                     Go back
                  </button>
               </div>
            )}
         </div>
      </>
   );
}
