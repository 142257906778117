/** @format */

import { getMessaging } from "firebase/messaging";
import { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sendOtp from "../../api/otp/sendOtp";
import verifyOtp from "../../api/otp/verifyOtp";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import YellowArrowIcon from "../../asset/icons/YellowArrowIcon";
import Loader from "../../component/Loader";
import firebase from "../../firebase";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userActions } from "../../store/user/reducer";
import userSelectors from "../../store/user/selectors";
import OtpInput from "react-otp-input";
import "./style.scss";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import { colors } from "@mui/material";

const OTPVerification = ({ isModalOpen, setIsModalOpen }: any) => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [loading, setloading] = useState(false);
   const location = useLocation();
   const { state }: any = location;
   const [timer, settimer] = useState(30);

   const { mid, qid }: any = useParams();
   const redirectPath = localStorage.getItem("redirect");

   const [otp, setotp] = useState("");

   const handleBackClick = () => {
      navigate(-2);
   };

   const onVerifyClickHandler = async () => {
      setloading(true);
      const phone_number = localStorage.getItem("phone_number");
      const email = localStorage.getItem("email");
      const { statusCode, payload, error } = await verifyOtp({
         //@ts-ignore
         phone_number: parseInt(phone_number),
         otp: otp,
         merchant_id: mid,
         //@ts-ignore
         email: email != "" ? email : null,
      });

      setloading(false);
      console.log(statusCode, "status code OTP");

      if (statusCode == 200) {
         const resData: any = payload;
         if (resData?.detail === "Invalid OTP") {
            toast.error("OTP is invalid");
         } else {
            toast.success("OTP is verified");
            localStorage.setItem("token", resData.jwt);
            dispatch(userActions.addAccessToken({ accessToken: payload }));
            setloading(false);
            if (redirectPath !== null) {
               if (qid !== undefined) {
                  navigate(`/${mid}/${qid}/${redirectPath}`);
               } else {
                  navigate(`/${mid}/${redirectPath}`);
               }
            } else {
               navigate(-2);
            }
         }
      } else {
         toast.error("Some error occured");
         console.log(error);
      }
   };

   const onResendButtonClickHandler = async () => {
      const { payload, error, statusCode } = await sendOtp(state);
      if (statusCode === 200 || statusCode === 201) {
         toast.success("OTP successfully resend to your phone number", {
            style: {
               background: "black",
               color: "#ffffff",
               textAlign: "center",
               borderRadius: "50px",
            },
         });
         settimer(30);
      }
   };

   useEffect(() => {
      setTimeout(() => {
         if (timer > 0) {
            settimer(timer - 1);
         }
      }, 1000);
   }, [timer]);

   const renderAuthenticationMethod = () => {
      const phone_number = localStorage.getItem("phone_number");
      const email = localStorage.getItem("email");
      if (phone_number !== "") return phone_number;
      if (email !== "") return email;
   };

   return (
      <div className="custom-font h-screen w-screen">
         <Toaster />
         <Loader Load={loading} />
         <div
            className="p-2 pt-4"
            onClick={handleBackClick}>
            <BackArrow />
            {/* <div className="login-text3">OTP verification</div> */}
         </div>
         <h2 className="text-3xl font-bold mt-3 mb-2 px-2 font-custom">Sign in and satisfy your hunger!</h2>
         <div className="px-2">
            <div className="otp-text-no font-custom font-normal">Enter the OTP sent to</div>
            <div className="otp-text-phone-no custom-font">{renderAuthenticationMethod()}</div>
            <div className="otp-verification-input-container">
               <OtpInput
                  value={otp}
                  onChange={setotp}
                  shouldAutoFocus={true}
                  numInputs={4}
                  inputType="number"
                  containerStyle={{ gap: "1rem" }}
                  inputStyle={{
                     height: "3.7rem",
                     width: "3.7rem",
                     borderRadius: 12,
                     outline: "none",
                     color: "black",
                     fontSize: "1.2rem",
                     fontWeight: "bold",
                     borderWidth: 1,
                     borderColor: "#D0D2D2",
                  }}
                  renderInput={(props) => <input {...props} />}
               />
            </div>
         </div>
         <div className="px-2">
            <button
               className={`${otp.length >= 4 ? "bg-[#0F6656] text-[#ffffff]" : "bg-[#0F191A1A] text-[#0F191A80]"} h-[4rem] w-[100%]  rounded-xl text-[1.4rem] mt-5 font-semibold`}
               disabled={!(otp.length === 4)}
               onClick={onVerifyClickHandler}>
               <div className="otp-verify-btn-text custom-font">Verify</div>
            </button>
         </div>
         <div className="otp-text-resend">
            <div className="otp-text-receive-code font-custom">Didn't receive the OTP?</div>
            <span
               className="font-custom"
               style={{
                  opacity: timer === 0 ? 1 : 0.5,
                  color: timer === 0 ? "#0F6656" : "#0F191A",
               }}
               onClick={() => timer === 0 && onResendButtonClickHandler()}>
               Resend
            </span>
            {timer > 0 && <div className="otp-resend-timer"> in 0:{timer}</div>}
         </div>
      </div>
   );
};

export default OTPVerification;
