/** @format */

import { Item, AddOnItem, Customization } from "src/interface/item";
import { useAppProps, useCartProps, OperationTypes, Cart } from "./storeTypes";

type SetState = (partial: Partial<useAppProps> | ((state: useAppProps) => Partial<useAppProps>), replace?: boolean) => void;

export const useCart = (set: SetState, get: () => useAppProps): useCartProps => {
   return {
      cart: {},
      oldCart: [],
      addMoreItemFlow: false,
      checkIfMerchantCartIsAvailable: () => {
         const { merchant_id } = get().merchantDetails;
         const availableMerchantIds = Object.keys(get().cart);
         return availableMerchantIds.includes(merchant_id as string);
      },
      getExisitngCartByMerchant: () => {
         const { merchantDetails, cart, checkIfMerchantCartIsAvailable } = get();
         const { merchant_id } = merchantDetails;
         let existingCart: Item[] = [];
         console.log("getExisitngCartByMerchant", cart);

         if (checkIfMerchantCartIsAvailable()) {
            existingCart = [...cart[merchant_id as string]];
            return existingCart;
         }
         return [] as Item[];
      },
      addOrRemoveItem: (ItemToCart: Item, operation: OperationTypes) => {
         const { checkAndApplyOrRemoveOffer, getExisitngCartByMerchant } = get();

         let existingCart = [...getExisitngCartByMerchant()];
         let itemIndex = existingCart.findIndex((item: Item) => item.item_id == ItemToCart.item_id);
         if (itemIndex !== -1) {
            let existingItem = { ...existingCart[itemIndex] };
            existingCart.splice(itemIndex, 1);
            let itemCount = existingItem.count;

            if (operation === "add") {
               itemCount = (itemCount as number) + 1;
            } else {
               itemCount = (itemCount as number) - 1;
            }

            existingItem.count = itemCount;
            existingItem.total_price = itemCount * existingItem.price_after_discount;

            if (itemCount > 0) {
               existingCart.splice(itemIndex, 0, existingItem);
            }
         } else {
            existingCart.push({
               ...ItemToCart,
               count: 1,
               add_ons: [],
               customizations: [],
               other_customization: "",
            });
         }
         set({
            cart: { ...get().cart, [ItemToCart.merchant_id]: existingCart },
         });
         checkAndApplyOrRemoveOffer();
      },
      addOrRemoveAddOn: (AddOnItem: AddOnItem, operation: OperationTypes) => {
         const { checkAndApplyOrRemoveOffer, getExisitngCartByMerchant } = get();

         let existingCart = [...getExisitngCartByMerchant()];
         const itemIndex = existingCart.findIndex((item: Item) => item.item_id === AddOnItem.item_id);
         let addonIndex = existingCart[itemIndex]?.add_ons?.findIndex((item: AddOnItem) => item.add_on_id === AddOnItem.add_on_id);
         if (addonIndex === -1) {
            existingCart[itemIndex]?.add_ons?.push({ ...AddOnItem, count: 1 });
         } else {
            let existingAddOnItem = existingCart[itemIndex].add_ons[addonIndex];
            existingCart[itemIndex].add_ons.splice(addonIndex, 1);
            let addOnItemCount = existingAddOnItem.count;
            if (operation === "add") {
               addOnItemCount = (addOnItemCount as number) + 1;
            } else {
               addOnItemCount = (addOnItemCount as number) - 1;
            }
            existingAddOnItem.count = addOnItemCount;
            if (addOnItemCount > 0) {
               existingCart[itemIndex].add_ons.splice(addonIndex, 0, existingAddOnItem);
            }
         }
         set({
            cart: { ...get().cart, [AddOnItem.merchant_id]: existingCart },
         });
         checkAndApplyOrRemoveOffer();
      },
      toggleCustomization: (Customization: Customization) => {
         const { getExisitngCartByMerchant } = get();

         let existingCart = [...getExisitngCartByMerchant()];
         const itemIndex = existingCart.findIndex((item: Item) => item.item_id == Customization.item_id);
         const customizationIndex = existingCart[itemIndex].customizations.findIndex((item: any) => item.customisation_id === Customization.customisation_id);
         if (customizationIndex !== -1) {
            if (Customization.selection_type === "checkbox") {
               if (existingCart[itemIndex].customizations[customizationIndex].selected_options?.includes(Customization.option)) {
                  existingCart[itemIndex].customizations[customizationIndex].selected_options?.splice(
                     existingCart[itemIndex].customizations[customizationIndex].selected_options?.indexOf(Customization.option),
                     1,
                  );
               } else {
                  existingCart[itemIndex].customizations[customizationIndex].selected_options?.push(Customization.option);
               }
            } else {
               existingCart[itemIndex].customizations[customizationIndex] = {
                  ...Customization,
                  selected_options: [Customization.option],
               };
            }
         } else {
            existingCart[itemIndex].customizations.push({
               ...Customization,
               selected_options: [Customization.option],
            });
         }
         set({
            cart: { ...get().cart, [Customization.merchant_id]: existingCart },
         });
      },
      update_other_customization: (item_id: string, value: string) => {
         const { merchantDetails, getExisitngCartByMerchant } = get();
         const { merchant_id } = merchantDetails;

         let existingCart = [...getExisitngCartByMerchant()];
         const itemIndex = existingCart.findIndex((item: Item) => item.item_id === item_id);
         if (existingCart[itemIndex]) {
            existingCart[itemIndex].other_customization = value;
         }
         set({
            cart: { ...get().cart, [merchant_id]: existingCart },
         });
      },
      clearCustomization: (Customization: Customization) => {
         const { getExisitngCartByMerchant } = get();
         let existingCart = [...getExisitngCartByMerchant()];
         const itemIndex = existingCart.findIndex((item: Item) => item.item_id === Customization.item_id);
         const customizationIndex = existingCart[itemIndex]?.customizations.findIndex((item: any) => item.customisation_id === Customization.customisation_id);

         if (customizationIndex !== -1) {
            existingCart[itemIndex].customizations.splice(customizationIndex, 1);
         }
         set({
            cart: { ...get().cart, [Customization.merchant_id]: existingCart },
         });
      },
      clearAddOnsAndCustomization: (item_id: string) => {
         const { merchantDetails, getExisitngCartByMerchant } = get();
         const { merchant_id } = merchantDetails;

         let existingCart = [...getExisitngCartByMerchant()];
         let itemIndex = existingCart.findIndex((item: Item) => item.item_id === item_id);
         existingCart[itemIndex].add_ons = [];
         existingCart[itemIndex].customizations = [];
         existingCart[itemIndex].other_customization = "";
         set({
            cart: { ...get().cart, [merchant_id]: existingCart },
         });
      },
      getCartTotal: () => {
         const { getExisitngCartByMerchant } = get();
         const { merchant_id } = get().merchantDetails;
         let total = 0;
         const cart = getExisitngCartByMerchant();
         cart.map((item: Item) => {
            total += (item.count as number) * item.price_after_discount;
            if (item.add_ons.length > 0) {
               item.add_ons.map((add_on_item) => {
                  total += (add_on_item.count as number) * add_on_item.price;
               });
            }
         });
         return Number(total.toFixed(2));
      },
      checkIsItemAddedToCart: (item_id: string) => {
         const { getExisitngCartByMerchant } = get();
         const cart = getExisitngCartByMerchant();
         const itemIndex = cart.findIndex((item: Item) => item.item_id === item_id);
         return itemIndex !== -1;
      },
      getCart: () => {
         const { merchant_id } = get().merchantDetails;
         return get().cart[merchant_id] || [];
      },
      setAddMoreItemFlow: () => {
         const { cart, merchantDetails } = get();
         const { merchant_id } = merchantDetails;
         set({
            oldCart: cart[merchant_id],
            cart: {
               ...cart,
               [merchant_id]: [],
            } as Cart,
            addMoreItemFlow: true,
         });
      },
      resetAddMoreItemFlow: () => {
         const { cart, merchantDetails, oldCart } = get();
         const { merchant_id } = merchantDetails;
         set({
            oldCart: [],
            cart: {
               ...cart,
               [merchant_id]: oldCart,
            } as Cart,
            addMoreItemFlow: false,
         });
      },
      getCartItem: (itemId: string) => {
         const { getExisitngCartByMerchant } = get();
         const cartItems = getExisitngCartByMerchant();
         const item = cartItems.find((i) => i.item_id === itemId);
         return item as Item;
      },
      resetCart: () => {
         const { cart, merchantDetails, resetLoyalty, resetOffer, resetOrder } = get();
         const { merchant_id } = merchantDetails;

         resetLoyalty();
         resetOffer();
         resetOrder();
         set({
            cart: {
               ...cart,
               [merchant_id]: [],
            } as Cart,
            oldCart: [],
            addMoreItemFlow: false,
         });
      },
   };
};
