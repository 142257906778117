import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NON_PROTECTED_ROUTES, PROTECTED_ROUTES } from "./routes";

const router = createBrowserRouter([
  ...PROTECTED_ROUTES,
  ...NON_PROTECTED_ROUTES,
]);

const AllRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AllRoutes;
